import React, { useEffect ,useState} from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMyProperty } from '../../reduxToolKit/slices/mySlice/myPropertySlice'
import { min } from 'moment/moment'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import UpgradePlan from './UpgradePlan'
import { changePropertyStatusSlice } from '../../reduxToolKit/slices/mySlice/changePropertyStatusSlice'
import axios from 'axios'
import { url } from '../../config/config'
import { isLoggedIn } from '../../utils'
import { toast, ToastContainer } from 'react-toastify'

const PropertyListing = () => {

    const dispatch = useDispatch();
   const navigate = useNavigate();
   const {state} = useLocation()
    const {propertyData}= useSelector((state)=>state.myPropertiyReducer)
    const userDetails = JSON.parse(window.localStorage.getItem("pgUser"));
    const [type, setType] = useState('ALL')
    const [search, setSearch] = useState('')
    const [upgradeModal , setUpgradeModal] = useState(false)
    const [planModal , setPlanModal] = useState(false);
    const [pgDetail , setPgDetail] = useState()

    useEffect(()=>{
        dispatch(fetchMyProperty({filter:type}))
        if(state?.propertyAdd){
            toast.success("Your Property add successfully. We will approved in few hrs")
        }
    },[])

    const handlegetFlat=()=>{
        dispatch(fetchMyProperty({filter: "FLAT"}))
        setType("FLAT")
    }
    const handlegetPG=()=>{
        dispatch(fetchMyProperty({filter: "PG"}))
        setType("PG")
    }

    const handlegetAll=()=>{
        dispatch(fetchMyProperty({filter: "ALL"}))
        setType("ALL")
    }


    const handleSearh=(e)=>{
        dispatch(fetchMyProperty({type, search:e.target.value}))
        setSearch(e.target.value)
    }

    const handleContact=(elem)=>{
        navigate("/property-responses",{state:{item:elem}})
    }

    const handleUpgradeModalClose=()=>{
        setUpgradeModal(false)
    }

    const handlePropertyStatus=async(e,item)=>{
      const {value} = e.target;
      let payload = {
       'type':item?.name?"PG":"FLAT",
        'id': item?._id,
        'status': value
      }
      let res = await axios.put(`${url}user/updateUserPropertyStatus`,payload, {
        headers: {
            "Authorization": isLoggedIn("pgUser")
        },
    });
    if(res?.data?.success){
        handlegetAll()
    }
    }
    const handleCancelPlan=async()=>{
        let payload = {
             'subscriptionId': pgDetail?.subscriptionId,
             'userId': userDetails?._id,
             propertyId:pgDetail?._id

           }
           let res = await axios.delete(`${url}user/deleteSubscription?subscriptionId=${payload.subscriptionId}&userId=${payload.userId}&propertyId=${payload.propertyId}`, {
             headers: {
                 "Authorization": isLoggedIn("pgUser")
             },
         });
         try {
            if(res?.data?.success){
                setPlanModal(false)
                toast.success("Your Plan Cancelled Successfully")
                 handlegetAll()
             }else{
                toast.warning(res?.data?.message)
             } 
         } catch (error) {
            toast.warning("Subscription not found")
         }
    }
console.log({propertyData})

  return (
    <>

<ToastContainer/>
    <Header />
    <section>
    <div class="my-proprties">
        <div class="right-search-area">
            <div class="activity-search">
             <div class="activity-search-tab">
                 <div class="activity-search-left">
                     <h3>My Properties</h3>
                     <div class="CommonTabs">
                         <ul class="nav nav-tabs">
                             <li class="nav-item">
                                 <a class="nav-link active" data-toggle="tab" href="#all" onClick={handlegetAll}>All</a>
                             </li>
                             <li class="nav-item">
                                 <a class="nav-link" data-toggle="tab" href="#pg" onClick={handlegetPG}>PG</a>
                             </li>
                             <li class="nav-item">
                                 <a class="nav-link" data-toggle="tab" href="#flat" onClick={handlegetFlat}>Flat</a>
                             </li>
                         </ul>                
                     </div>
                 </div>
                 <div class="activity-search-right">
                     <div class="form-group">
                         <input type="search" onChange={(e)=>handleSearh(e)} value={search} class="form-control" placeholder="Search Address, City, ZIP code" />
                     </div>

                     <div class="Actions">Only active &nbsp;
                         <label class="switch">
                             <input type="checkbox" />
                             <span class="slider"></span>
                         </label>
                     </div>
                 </div>
             </div>
             <div class="tab-content">
                 <div class="tab-pane fade active show" id="all">
                   

                     {propertyData?.data?.length>0?propertyData?.data?.map((item)=>{

                       let minAmount = item?.expectedRent ? 0 : Math?.min(
                        ...item?.pgRooms?.map((item) => item?.monthlyRent)
                      );

                      return(

                        <div class="search-body">
                        <div class="search-verified">
                            <span class={item?.status =="Active"?"green-verfied":"red-verfied"}>{item?.status}</span>
                            <div class="left-search-verified">
                                <img src={item?.propertyMedia?.filter((elem) => elem.default)?.[0]?.mediaUrl}alt="" />
                            </div>

                            <div class="right-properties-listing">
                                <aside>
                                    <h3>{item?.name || item?.apartmentName}</h3>
                                    <p>Property ID <span>{item?.flat_id ||item?.pg_id}</span></p>
                                    <select className='property-staus' onChange={(e)=>handlePropertyStatus(e,item)} value={item?.status}>
                                <option>Change Status</option>
                                <option value="RentOut">Rent Out</option>
                                <option value="Deactive">Deactivate</option>
                                <option value="Active">Activate</option>
                            </select>
                                    {/* <figure>
                                        <span>
                                            <img src="images/edit.png" alt="" />
                                        </span>
                                       <span data-toggle="modal" data-target="#Delete1Modal"><img src="images/delete.png" alt="" /></span>
                                    </figure> */}
                                </aside>
                                <h3> {item?.BHKType} Flats for Rent</h3>
                                <article>
                                    <div>
                                        <p><img src="images/black-location.png" alt="" />{item?.address}</p>
                                        <h5>₹ {item?.expectedRent?item?.expectedRent: minAmount}</h5>
                                    </div>
                                    <button  state={item} class="button" onClick={()=>handleContact(item)}>Contacted ({item?.contacts})</button>
                                </article>
                            </div>    
                            
                        </div>

                        <div class="float-properties-option">
                            <ul class="flatOption">
                                <li>
                                    <p>Purchase Plan Date</p>
                                    <h6>{item?.subscriptionDetails?.createdAt?.split("T")[0]}</h6>
                                </li>
                                <li>
                                    <p>Plan Expire Date</p>
                                    <h6>{item?.subscriptionDetails?.endDate?item?.subscriptionDetails?.endDate?.split("T")[0]:"N.A"}</h6>
                                </li>
                                <li> 
                                    <p>Auto Renewal</p> 
                                    <h6>Yes</h6>
                                </li>
                                <li>
                                    <p>Plan Type</p>
                                    <h6>{item?.subscriptionType=="yearly"?"Yearly":item?.subscriptionType=="monthly"?"Monthly":"Free"}</h6>
                                </li>
                                <li>
                                    <p>Plan Name</p>
                                    <h6>{item?.planDetails?.[0]?.basicInfo?.planName}</h6>
                                </li>
                            </ul> 
                            <ul>
                                <li><a class="button" onClick={()=>{setUpgradeModal(true);setPgDetail(item)}}>Upgrade</a>  </li>
                            </ul>                             
                        </div>
                    </div>
                      )
                 }
                      
                    ):<div><img src='https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?w=740&t=st=1724762289~exp=1724762889~hmac=1ea735ed1a5357935c2a8703441adb2a16dd5d160ff70884c1ab9273f6b43f0e' style={{display:"block", margin:"auto",width:"45%"}}/></div>}
                 </div>

                 <div class="tab-pane fade" id="pg">
                 {propertyData?.data?.length>0?propertyData?.data?.map((item)=>{
                   
                   let minAmount = item?.expectedRent ? 0 : Math?.min(
                    ...item?.pgRooms?.map((item) => item?.monthlyRent)
                  );


                      return(

                        <div class="search-body">
                        <div class="search-verified">
                            <span class={item?.status =="Active"?"green-verfied":"red-verfied"}>{item?.status}</span>
                            <div class="left-search-verified">
                                <img src={item?.propertyMedia?.filter((elem) => elem.default)?.[0]?.mediaUrl}alt="" />
                            </div>

                            <div class="right-properties-listing">
                                <aside>
                                <h3>{item?.name}</h3>

                                   <p>Property ID <span>{item?.flat_id ||item?.pg_id}</span></p>
                                    <select className='property-staus' onChange={(e)=>handlePropertyStatus(e,item)} value={item?.status}>
                                <option>Change Status</option>
                                <option value="RentOut">Rent Out</option>
                                <option value="Deactive">Deactivate</option>
                                <option value="Active">Activate</option>
                            </select>
                                    {/* <figure>
                                        <span>
                                            <img src="images/edit.png" alt="" />
                                        </span>
                                       <span data-toggle="modal" data-target="#Delete1Modal"><img src="images/delete.png" alt="" /></span>
                                    </figure> */}
                                </aside>
                                <h3> {item?.BHKType} Flats for Rent</h3>
                                <article>
                                    <div>
                                        <p><img src="images/black-location.png" alt="" />{item?.address}</p>
                                        <h5>₹ {minAmount}</h5>
                                    </div>
                                    <button class="button" onClick={()=>handleContact(item)}>Contacted ({item?.contacts})</button>
                                </article>
                            </div>    
                            
                        </div>

                        <div class="float-properties-option">
                        <ul class="flatOption">
                                <li>
                                    <p>Purchase Plan Date</p>
                                    <h6>{item?.subscriptionDetails?.createdAt?.split("T")[0]}</h6>
                                </li>
                                <li>
                                    <p>Plan Expire Date</p>
                                    <h6>{item?.subscriptionDetails?.endDate?.split("T")[0]}</h6>
                                </li>
                                <li>
                                    <p>Auto Renewal</p>
                                    <h6>Yes</h6>
                                </li>
                                <li>
                                    <p>Plan Type</p>
                                    <h6>{item?.subscriptionType=="yearly"?"Yearly":item?.subscriptionType=="monthly"?"Monthly":"Free"}</h6>
                                </li>
                                <li>
                                    <p>Plan Name</p>
                                    <h6>{item?.planDetails?.[0]?.basicInfo?.planName}</h6>
                                </li>
                            </ul> 
                            <ul>
                                <li><a class="button" onClick={()=>{setUpgradeModal(true);setPgDetail(item)}}>Upgrade</a>  </li>
                            </ul>                             
                        </div>
                    </div>
                      )
                 }
                      
                    ):<div><img src='https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?w=740&t=st=1724762289~exp=1724762889~hmac=1ea735ed1a5357935c2a8703441adb2a16dd5d160ff70884c1ab9273f6b43f0e' style={{display:"block", margin:"auto",width:"45%"}}/></div>}
               
                 </div>
 
                 <div class="tab-pane fade" id="flat">
                    {propertyData?.data?.length>0?propertyData?.data.map((item)=>
                          <div class="search-body">
                          <div class="search-verified">
                              <span class={item?.status =="Active"?"green-verfied":"red-verfied"}>{item?.status}</span>
                              <div class="left-search-verified">
                                  <img src={item?.propertyMedia?.filter((elem) => elem.default)?.[0]?.mediaUrl}alt="" />
                              </div>
 
                              <div class="right-properties-listing">
                                  <aside>
                                  <h3>{item?.apartmentName}</h3>

                                      <p>Property ID <span>{item?.flat_id ||item?.pg_id}</span></p>
                            <select className='property-staus' onChange={(e)=>handlePropertyStatus(e,item)} value={item?.status}>
                                <option>Change Status</option>
                                <option  value="RentOut">Rent Out</option>
                                <option  value="Deactive">Deactivate</option>
                                <option value="Active">Activate</option>
                            </select>

                                      {/* <figure>
                                          <span>
                                              <img src="images/edit.png" alt="" />
                                          </span>
                                         <span data-toggle="modal" data-target="#Delete1Modal"><img src="images/delete.png" alt="" /></span>
                                      </figure> */}
                                  </aside>
                                  <h3> {item?.BHKType} Flats for Rent</h3>
                                  <article>
                                      <div>
                                          <p><img src="images/black-location.png" alt="" />{item?.address}</p>
                                          <h5>₹ {item?.expectedRent}</h5>
                                      </div>
                                      <button class="button" onClick={()=>handleContact(item)}>Contacted ({item?.contacts})</button>

                                  </article>
                              </div>    
                              
                          </div>
 
                          <div class="float-properties-option">
                          <ul class="flatOption">
                                <li>
                                    <p>Purchase Plan Date</p>
                                    <h6>{item?.subscriptionDetails?.createdAt?.split("T")[0]}</h6>
                                </li>
                                <li>
                                    <p>Plan Expire Date</p>
                                    <h6>{item?.subscriptionDetails?.endDate?.split("T")[0]}</h6>
                                </li>
                                <li>
                                    <p>Auto Renewal</p>
                                    <h6>Yes</h6>
                                </li>
                                <li>
                                    <p>Plan Type</p>
                                    <h6>{item?.subscriptionType=="yearly"?"Yearly":item?.subscriptionType=="monthly"?"Monthly":"Free"}</h6>
                                </li>
                                <li>
                                    <p>Plan Name</p>
                                    <h6>{item?.planDetails?.[0]?.basicInfo?.planName}</h6>
                                </li>
                            </ul> 
                              <ul>
                                  <li><a class="button" onClick={()=>{setUpgradeModal(true);setPgDetail(item)}}>Upgrade</a>  </li>
                              </ul>                             
                          </div>
                      </div>
                    ):<div><img src='https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?w=740&t=st=1724762289~exp=1724762889~hmac=1ea735ed1a5357935c2a8703441adb2a16dd5d160ff70884c1ab9273f6b43f0e' style={{display:"block", margin:"auto",width:"45%"}}/></div>}
               
                 </div>
             </div>
            </div>                        
         </div>
    </div>
   </section>

   <Modal
        show={upgradeModal}
        onHide={handleUpgradeModalClose}
        className="ModalBox"
      >
        <Modal.Body>
          <div class="Category">
            <a
              onClick={handleUpgradeModalClose}
              class="CloseModal"
              data-dismiss="modal"
              style={{ cursor: "pointer" }}
            >
              ×
            </a>
            <h3>Manage Your Plan</h3>
            <h4>Would you like to update your current plan or cancel it?</h4>
          </div>
        </Modal.Body>
        <Modal.Footer className='d-block'>
        <div className='d-flex justify-content-between'>
                <div><button class="button danger" onClick={()=>handleCancelPlan()}>Cancel</button>  </div>
                <div><button class="button" onClick={()=>{setPlanModal(true);setUpgradeModal(false)}}>Upgrade</button>  </div>
            </div> 
        </Modal.Footer>
      </Modal>

   <Footer />
    {planModal?<UpgradePlan planModal={planModal} setPlanModal={setPlanModal} pgDetail={pgDetail}/>:""}
    </>
  )
}

export default PropertyListing