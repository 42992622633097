import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  MarkerF,
} from "@react-google-maps/api";

import markerImage from "../assets/images/location.png";
import axios from "axios";
import { url } from "../config/config";
import locImg from "../assets/images/map-loc-img.png"

const containerStyle = {
  width: "100%",
  height: "600px",
};

const markerIconSize = {
  width: 50, // width in pixels
  height: 50, // height in pixels
  color:"#fd701e"
};

const MapView = ({mapData, currentLocation}) => {
  const [MarkerStatic, setMarker] = useState([]);
  // let url =https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${currentLocation.lat},${currentLocation.lng}&radius=5000&type=${mapData.place}&key=AIzaSyB0oVP9ASJypwf7BLW86zTwqqM2iSI0DuE`;

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB0oVP9ASJypwf7BLW86zTwqqM2iSI0DuE", // Replace with your API key
  });

  const [map, setMap] = useState(null);

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      MarkerStatic.forEach((marker) => bounds.extend(marker.position));
      map.fitBounds(bounds);
    }
  }, [map]);

  // const FetchNearPlaces = async () => {
  //   try {
  //     let RespResults = [];
  //     const COMPLETE_URL = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${currentLocation.lat},${currentLocation.lng}&radius=5000&type=${mapData.place}&key=AIzaSyB0oVP9ASJypwf7BLW86zTwqqM2iSI0DuE`;
  //     let response = await axios.get(COMPLETE_URL);
  //     if (response.status == 200 && response.data.status == "OK") {
  //       RespResults =
  //         response?.data?.results?.length > 0 &&
  //         response.data?.results?.map((item) => {
  //           return {
  //             position: {
  //               lat: item?.geometry?.location?.lat,
  //               lng: item?.geometry?.location?.lng,
  //             },

  //             placeName: item?.name,
  //             icon: item?.icon,
  //           };
  //         });

  //         let mainLocation ={position:currentLocation, placeName:"PG Location", icon: markerImage}
  //         if(currentLocation){
  //           RespResults.push(mainLocation)
  //         }

  //       setMarker(RespResults);
  //     } else {
  //       RespResults = [];
  //     }
  //     return RespResults;
  //   } catch (error) {
  //     console.log("FetchNearPlaces----error----->", error);
  //   }
  // };

  const fetchLocation=async()=>{
    let RespResults = [];
    const response = await axios.get(`${url}user/google?lat=${currentLocation?.lat}&lng=${currentLocation?.lng}&type=${mapData?.place}` ,{
    });
    try {
      if (response?.status == 200 && response?.data?.data?.status == "OK") {
        RespResults =
          response?.data?.data?.results?.length > 0 &&
          response.data?.data?.results?.map((item) => {
            return {
              position: {
                lat: item?.geometry?.location?.lat,
                lng: item?.geometry?.location?.lng,
              },

              placeName: item?.name,
              icon: item?.icon,
            };
          });

          let mainLocation ={position:currentLocation, placeName:"PG Location", icon: locImg}
          if(currentLocation){
            RespResults.push(mainLocation)
          }

        setMarker(RespResults);
      } else {
        RespResults = [];
      }
      return RespResults;
    } catch (error) {
        console.log(error)
    }
  }
  

  useEffect(() => {
    // if(currentLocation?.lat){
      fetchLocation()
    // }
  }, [mapData,currentLocation]);

  return (
    isLoaded &&
    MarkerStatic && (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{ lat: currentLocation?.lat, lng: currentLocation?.lng }}
        zoom={14}
      >
        {MarkerStatic.map((marker, i) => (
          <Marker
          // scaledSize={(4,2)}
            key={marker.i}
            position={marker.position}
            title={marker.placeName}
            // icon={marker.icon}
            icon={{
              url: marker.icon,  // Custom icon URL
              scaledSize: 
                marker.placeName === "PG Location"
                  ? new window.google.maps.Size(50, 50)  // Larger size for PG Location
                  : new window.google.maps.Size(30, 30),  // Default size for others
              fillColor: 
                marker.placeName === "PG Location" 
                  ? "#fd701e"  // Different color for PG Location
                  : "#fd701e",  // Default color for others
            }}
          />
        ))}
      </GoogleMap>
    )
  );
};


export default MapView;

