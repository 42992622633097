import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPrivacyContent } from '../reduxToolKit/slices/staticContentSlice.js/privacyApiSlice'

const PrivacyPolicy = () => {


    const {privacyData} = useSelector((state)=> state.privacyReducer)

    const dispatch = useDispatch()

    useEffect(()=>{
      window.scrollTo(0, 0);
        dispatch(fetchPrivacyContent())

    },[])
  return (
    <>
    <Header />

    <section>
        <div class="privacy-area">
            <figure>
                <img src="images/privacy-banner.png" alt="" />
            </figure>
            {/* <figcaption>Privacy Policy</figcaption> */}

            <div class="container">
            <h4>Privacy & Policy</h4>
            <p
            dangerouslySetInnerHTML={{
              __html: privacyData?.data?.[0]?.description,
            }}
            className="editor"
          ></p>  
            </div>
        </div>
    </section>


    <Footer />


    </>
  )
}

export default PrivacyPolicy