import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchPgResultDetails } from "../reduxToolKit/slices/pgSlices/pgResultDetails";
import StarRatings from "react-star-ratings";
import { dateTimeFormat } from "../component/helpers/dateTimeFormat";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import MapView from "./MapView";
import moment from "moment";

// import tvImage from "../assets/images/tv.png";
// import singelBed from "../assets/images/singleBed.png";
// import acImage from "../assets/images/acHeating.png";
// import doubleBed from "../assets/images/doubleBed.png";
// import tableChair from "../assets/images/tableChair.png";
// import blanketImage from "../assets/images/blanket.png";
// import matress from "../assets/images/matress.png";
// import sideTable from "../assets/images/sideTable.png";
// import cupboard from "../assets/images/cupBoard.png";
// import hotWaterImage from "../assets/images/hotWater.png";

import { FaTv } from "react-icons/fa";
import { FaBed } from "react-icons/fa";
import { TbAirConditioning } from "react-icons/tb";
import { GiSlicedBread } from "react-icons/gi";
import { PiPicnicTableBold } from "react-icons/pi";
import { BiSolidBlanket } from "react-icons/bi";
import { FaMattressPillow } from "react-icons/fa6";
import { LuBedDouble } from "react-icons/lu";
import { MdOutlineChair } from "react-icons/md";
import { GiThermometerHot } from "react-icons/gi";
import { BsFillRouterFill } from "react-icons/bs";
import { GiCooler } from "react-icons/gi";
import { BiSolidFridge } from "react-icons/bi";
import { GiEscalator } from "react-icons/gi";
import { SiAdguard } from "react-icons/si";
import { CgGym } from "react-icons/cg";
import { RiFirstAidKitFill } from "react-icons/ri";
import { FaSquareParking } from "react-icons/fa6";
import { LuMicrowave } from "react-icons/lu";
import { GrUserManager } from "react-icons/gr";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { isLoggedIn } from "../utils";
import { Line } from "rc-progress";
import { createRatingApi } from "../reduxToolKit/slices/pgSlices/addRatingSlice";
import { fetchReviewlisting } from "../reduxToolKit/slices/pgSlices/reviewListingSlice";
import { addContactOwnerApi } from "../reduxToolKit/slices/mySlice/addContactOwner";
import Loader from "./Loader";

const DropDownInterface = [
  {
    label: "Currently staying in the Pg",
    value: "Currently staying in the Pg",
  },
  {
    label: "Not staying in this Pg",
    value: "Not staying in this Pg",
  },
  {
    label: "Have stayed in this PG 6 months ago",
    value: "Have stayed in this PG 6 months ago",
  },
  {
    label: "Have stayed in this PG a year ago",
    value: "Have stayed in this PG a year ago",
  },
  {
    label: "Never stay in this PG",
    value: "Never stay in this PG",
  },
];

const initialState = {
  type: "PG",
  locality: "",
  pgName: "",
  propId: "",
  currentStatus: "",
  rating: 0,
  feedbacks: "",
  scrollStateName: "",
  errorMsg: {},
};

const roomAmenitiesArray = [
  {
    name: "Television",
    img: <FaTv style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "Single Bed",
    img: <FaBed style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "AC/ Heating",
    img: <TbAirConditioning style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "Mattress + Pillow",
    img: <FaMattressPillow style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "Table + Chair",
    img: <MdOutlineChair style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "Blanket/Quilt",
    img: <BiSolidBlanket style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "Side Table",
    img: <PiPicnicTableBold style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "Queen Bed",
    img: <LuBedDouble style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "Hot Water",
    img: <GiThermometerHot style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "Cupboard",
    img: <GiSlicedBread style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
];
const amenitiesArray = [
  {
    name: "Power-Back Up",
    img: (
      <GiThermometerHot
        style={{ color: "#FD701E", fontSize: "22px", marginRight: "10px" }}
      />
    ),
  },
  {
    name: "Lift",
    img: (
      <GiEscalator
        style={{ color: "#FD701E", fontSize: "22px", marginRight: "10px" }}
      />
    ),
  },
  {
    name: "Wi-fi",
    img: (
      <BsFillRouterFill
        style={{ color: "#FD701E", fontSize: "22px", marginRight: "10px" }}
      />
    ),
  },

  {
    name: "Water Cooler",
    img: (
      <GiCooler
        style={{ color: "#FD701E", fontSize: "22px", marginRight: "10px" }}
      />
    ),
  },
  {
    name: "Fridge",
    img: (
      <BiSolidFridge
        style={{ color: "#FD701E", fontSize: "22px", marginRight: "10px" }}
      />
    ),
  },
  {
    name: "Microwave",
    img: (
      <LuMicrowave
        style={{ color: "#FD701E", fontSize: "22px", marginRight: "10px" }}
      />
    ),
  },
  {
    name: "First Aid Kit",
    img: (
      <RiFirstAidKitFill
        style={{ color: "#FD701E", fontSize: "22px", marginRight: "10px" }}
      />
    ),
  },
  {
    name: "Warden/ Manager",
    img: (
      <GrUserManager
        style={{ color: "#FD701E", fontSize: "22px", marginRight: "10px" }}
      />
    ),
  },
  {
    name: "Security Guard",
    img: (
      <SiAdguard
        style={{ color: "#FD701E", fontSize: "22px", marginRight: "10px" }}
      />
    ),
  },
  {
    name: "Parking",
    img: (
      <FaSquareParking
        style={{ color: "#FD701E", fontSize: "22px", marginRight: "10px" }}
      />
    ),
  },
  {
    name: "Gym",
    img: (
      <CgGym
        style={{ color: "#FD701E", fontSize: "22px", marginRight: "10px" }}
      />
    ),
  },
];
const PgDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const [mapData, setMapData] = useState({ place: "university" });

  const { reviewData } = useSelector((state) => state.reviewReducer);

  const [reviewType, setReviewType] = useState("Top-Rated");

  const [ContacModal, setContact] = useState(false);
  const [bannerImage , setBannerImage] = useState()
  const handleContactModalClose = () => setContact(false);
  const handleContactModalShow = (id) => {
    setContact(true);
  };

  const [activeTab, SetActiveTavb] = useState("Details");

  const { pgResult } = useSelector((state) => state.pgResult);
 const loader  = useSelector((state) => state.pgResult.status)
  const nearPlace = [
    {
      label: "Education",
      value: "university",
    },
    {
      label: "Restaurants",
      value: "restaurant",
    },
    {
      label: "Metro Station",
      value: "subway_station",
    },
    {
      label: "Shopping Center",
      value: "shopping_mall",
    },
    {
      label: "Hospital",
      value: "hospital",
    },
  ];

  const dispatch = useDispatch();

  const [iState, updateState] = useState(initialState);

  const {
    currentStatus,
    feedbacks,
    rating,
    locality,
    pgName,
    type,
    errorMsg,
    scrollStateName,
  } = iState;

  const [ImageModal, SetImageModal] = useState({
    photoIndex: 0,
    isOpen: false,
    default:false
  });

  const { photoIndex, isOpen } = ImageModal;

  const [DeleteModal, setDelete] = useState(false);
  const handleDeleteModalClose = () => {
    updateState({ ...iState, rating: 0, currentStatus: "", feedbacks: "" });
    setDelete(false);
  };
  const handleDeleteModalShow = (id) => {
    setDelete(true);
  };

  const { state } = useLocation();
  useEffect(() => {
    dispatch(fetchPgResultDetails({ id: id }));
    if(state?.scroll){
      window.scrollTo(0, 1700);
    }else{
      window.scroll(0, 0);
    }
  }, []);

  useEffect(() => {
    updateState({
      ...iState,
      pgName: pgResult?.data?.[0]?.name,
      locality: pgResult?.data?.[0]?.city,
    });
    setBannerImage(pgResult?.data?.[0]?.propertyMedia?.filter(
      (ele) => ele?.default
    ))
  }, [pgResult]);

  const initialRatingCounts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
  const ratingCounts =
    pgResult?.data?.[0]?.ratings?.length > 0
      ? pgResult?.data?.[0]?.ratings.reduce((acc, curr) => {
          acc[curr.rating] = (acc[curr.rating] || 0) + 1;
          return acc;
        }, initialRatingCounts)
      : null;

  const handleValidation = () => {
    let flag = true;
    let errorMsg = {};

    if (!currentStatus) {
      flag = false;
      errorMsg.currentStatus = "Current status field is required";
    }

    if (rating == 0) {
      flag = false;
      errorMsg.rating = " Rating  is required";
    }

    if (!feedbacks) {
      flag = false;
      errorMsg.feedbacks = "Feedback  is required";
    }

    updateState({ ...iState, errorMsg });

    return flag;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let isValid = handleValidation();

    if (isValid) {
      let data = {
        type: "PG",
        locality: locality,
        pgName: pgName,
        propId: pgResult?.data?.[0]?._id,
        currentStatus: currentStatus,
        rating: rating,
        feedbacks: feedbacks,
      };

      dispatch(createRatingApi(data))
        .then((res) => {
          if (res.payload.success) {
            toast.success("Review added successfully");
            setDelete(false);
            dispatch(fetchPgResultDetails({ id: id }));

            dispatch(
              fetchReviewlisting({
                type: "PG",
                filter: reviewType,
                propertyId: state?._id,
              })
            );

            updateState({
              ...iState,
              rating: 0,
              currentStatus: "",
              feedbacks: "",
            });

            toast.success(res.message);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {});
    }
  };

  const scrollHandle = (id) => {
    let element;

    if (id == "Occupancy") {
      SetActiveTavb("Occupancy");
      element = document.getElementById("Charges");
      updateState({ ...iState, scrollStateName: "Charges" });
    } else {
      SetActiveTavb(id);
      element = document.getElementById(id);
      updateState({ ...iState, scrollStateName: id });
    }

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  // const bannerImage = pgResult?.data?.[0]?.propertyMedia?.filter(
  //   (ele) => ele?.default
  // );
  // console.log(pgResult?.data?.[0]?.location, "sadfgh")

  useEffect(() => {
    dispatch(
      fetchReviewlisting({
        type: "PG",
        filter: reviewType,
        propertyId: state?._id,
      })
    );
  }, [reviewType, state]);

  const handleCreateOwner = () => {
    if (isLoggedIn("pgUser")) {
      setContact(true);
      dispatch(
        addContactOwnerApi({
          propId: state?._id,
          type: "PG",
        })
      );
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      {loader!="succeeded"?<Loader/>:""}
      <ToastContainer />

      <Header />
      <section>
        <div class="PropertyBox">
          <div class="PropertyHead">
            <figure>
              <img
                src={
                  bannerImage?.length > 0
                    ? bannerImage?.[0]?.mediaUrl
                    : require("../assets/images/property-1.png")
                }
                style={{height:"100%",width:"100%"}}
              />

              {pgResult?.data?.[0]?.verified && (
                <div class="VerifyOverlay">
                  <span>
                    <img src={require("../assets/images/verified.png")} />
                  </span>
                  Verified
                </div>
              )}
            </figure>
            <figcaption>
              <aside>
                <h3>{pgResult?.data?.[0]?.name} </h3>
                <StarRatings
                  rating={pgResult?.data?.[0]?.rating}
                  starRatedColor="#f89117"
                  starDimension="20px"
                  starSpacing="0px"
                />
                <span>
                  {" "}
                  {pgResult?.data?.[0]?.rating?.toFixed(1)}(
                  {pgResult?.data?.[0]?.ratings?.length} Reviews)
                </span>
              </aside>
              <h4>
                <i class="fa-solid fa-location-dot"></i>{" "}
                {pgResult?.data?.[0]?.address}
              </h4>
              <p>
                <label>Occupancy Type:</label>

                {pgResult?.data?.[0]?.pgRooms?.map((room, i) => {
                  return (
                    <span>
                      {room?.roomType == "Four Bed" ? "Others" : room?.roomType}
                      {pgResult?.data?.[0]?.pgRooms?.length - 1 !== i
                        ? ","
                        : ""}
                    </span>
                  );
                })}
              </p>
              <ul>
                <li>{pgResult?.data?.[0]?.preferredGuest}</li>

                {pgResult?.data?.[0]?.includedMeals
                  ?.slice(0, 5)
                  ?.map((rule) => (
                    <li>{rule}</li>
                  ))}
              </ul>
            </figcaption>
          </div>
        </div>
      </section>

      <section>
        <div class="OrderDetail">
          <ul class="Direction">
            <li>
              <a
                onClick={() => scrollHandle("Details")}
                className={activeTab == "Details" ? "active" : ""}
              >
                Property Details
              </a>
            </li>
            <li>
              <a
                onClick={() => scrollHandle("Occupancy")}
                className={activeTab == "Occupancy" ? "active" : ""}
              >
                Occupancy
              </a>
            </li>
            <li>
              <a
                onClick={() => scrollHandle("Charges")}
                className={activeTab == "Charges" ? "active" : ""}
              >
                Charges
              </a>
            </li>
            <li>
              <a
                onClick={() => scrollHandle("Amenities")}
                className={activeTab == "Amenities" ? "active" : ""}
              >
                Amenities
              </a>
            </li>
            <li>
              <a
                onClick={() => scrollHandle("Rules")}
                className={activeTab == "Rules" ? "active" : ""}
              >
                House Rules
              </a>
            </li>
            <li>
              <a
                onClick={() => scrollHandle("Location")}
                className={activeTab == "Location" ? "active" : ""}
              >
                Location
              </a>
            </li>
            <li>
              <a
                onClick={() => scrollHandle("Review")}
                className={activeTab == "Review" ? "active" : ""}
              >
                Reviews
              </a>
            </li>
          </ul>
        </div>
      </section>

      <section>
        <div class="HotelDetailsArea">
          <div class="hotel-area">
            <div class="HotelDetailsLeft">
              <figure
                onClick={() => SetImageModal({ ...ImageModal, isOpen: true,default:true })}
              >
                <a data-toggle="modal" data-target="#ViewImageModal" >
                  <img
                    // src={
                    //   pgResult?.data?.[0]?.propertyMedia
                    //   ?.filter((ele) => ele?.mediaType == "Image")?.length >0 ? pgResult?.data?.[0]?.propertyMedia
                    //   ?.filter((ele) => ele?.mediaType == "Image")?.[0]?.mediaUrl
                    //     : require("../assets/images/property-1.png")
                    // }
                    src={
                      bannerImage?.length > 0
                        ? bannerImage?.[0]?.mediaUrl
                        : require("../assets/images/property-1.png")
                    }
                  />
                </a>
              </figure>
              <ul>
                {pgResult?.data?.[0]?.propertyMedia
                  ?.filter((ele) => ele?.mediaType == "Image")
                  ?.slice(0, 3)
                  ?.map((image, i) => (
                    <li>
                      <figure
                        onClick={() =>
                          SetImageModal({
                            ...ImageModal,
                            isOpen: true,
                            photoIndex: i,
                          })
                        }
                      >
                        <a data-toggle="modal" data-target="#ViewImageModal" style={{width:"100%",height:"100px"}}>
                          <img
                            src={image?.mediaUrl}
                            style={{ height: "100%",width:"100%" }}
                          />
                        </a>

                        {i == 2 && (
                          <figcaption
                            onClick={() =>
                              SetImageModal({ ...ImageModal, isOpen: true })
                            }
                          >
                            +{" "}
                            {
                              pgResult?.data?.[0]?.propertyMedia?.filter(
                                (ele) => ele?.mediaType == "Image"
                              )?.length
                            }{" "}
                            MORE
                          </figcaption>
                        )}
                      </figure>
                    </li>
                  ))}
              </ul>
            </div>
            <div class="HotelDetailsRight">
              <article>
                <aside>
                  <h2
                    style={{
                      fontSize: "22px",
                      fontWeight: "700",
                      textAlign: "left",
                    }}
                  >
                    {pgResult?.data?.[0]?.name}
                  </h2>
                </aside>
              </article>

              <article>
                <aside>
                  <h3>{pgResult?.data?.[0]?.address}</h3>
                </aside>
                <aside>
                  <a onClick={() => scrollHandle("Location")}>
                    <span>
                      <img src={require("../assets/images/location.png")} />
                    </span>
                    View on map
                  </a>
                </aside>
              </article>
              <div className="TableListNew">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          Preferred Tenant
                          <span>{pgResult?.data?.[0]?.preferredGuest}</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          Available for{" "}
                          <span> {pgResult?.data?.[0]?.availableFor}</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          Room Cleaning{" "}
                          <span>
                            {pgResult?.data?.[0]?.roomCleaning
                              ? "Available"
                              : "Not Available"}
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          Available From
                          <span>
                            {moment(pgResult?.data?.[0]?.availableFrom).format(
                              "DD/MM/YYYY"
                            )}
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          Ac Room{" "}
                          <span>
                            {pgResult?.data?.[0]?.roomAmenities.some((item) =>
                              item.includes("AC/ Heating")
                            )
                              ? "Available"
                              : "Not Available"}
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          Parking{" "}
                          <span>
                            {pgResult?.data?.[0]?.parking
                              ? pgResult?.data?.[0]?.vehicleType == "Both"
                                ? "Car,Bike"
                                : pgResult?.data?.[0]?.vehicleType
                              : "Not Available"}
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          Laundry
                          <span>
                            {pgResult?.data?.[0]?.isLaundryService
                              ? "Available"
                              : "Not Available"}
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          Food Included{" "}
                          <span>
                            {pgResult?.data?.[0]?.includedMeals?.length > 2
                              ? "All Meals"
                              : pgResult?.data?.[0]?.includedMeals
                                  ?.map((food) => food)
                                  .join(", ")}
                          </span>
                        </p>
                      </td>
                      <td>
                      <p>
                          Notice Period
                          <span>
                            {pgResult?.data?.[0]?.noticePeriodDuration
                              ? `${pgResult?.data?.[0]?.noticePeriodDuration} Days `
                              : "0 Days"}
                          </span>
                        </p>
                      </td>
                    </tr>
                    {/* <tr>
                      <td>
                      <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
              <div class="BrandButton text-right">
                <a class="button" onClick={handleCreateOwner}>
                  Contact Owner
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="writereview">
          <div class="OrderDetails WriteReview" id="Reviews">
            <h5>
              Show some love &amp; Let us know how good <br /> this PG is
            </h5>
            <div class="TitleBox">
              <a
                class="TitleLink Button"
                onClick={() => {
                  if (isLoggedIn("pgUser")) {
                    setDelete(true);
                  }
                }}
              >
                Write a review &nbsp;
                <i class="fa-solid fa-arrow-right text-white"></i>{" "}
              </a>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="writereview">
          <div class="OrderDetails" id="Charges">
            <div class="row">
              {pgResult?.data?.[0]?.pgRooms?.map((item) => (
                <div class="col-md-3">
                  <div class="ViewContactBox">
                    {/* <figure>
                    <img
                      src={
                        item?.roomImage?.length > 0
                          ? item?.roomImage?.[0]
                          : require("../assets/images/property-1.png")
                      }
                    />
                  </figure> */}
                    <figcaption>
                      <h3>
                        {item?.roomType == "Four Bed"
                          ? "Others"
                          : item?.roomType}
                      </h3>
                      <p>
                        <label>Rent</label>
                        <span>Rs. {item?.monthlyRent}</span>
                      </p>
                      <p>
                        <label>Deposit</label>
                        <span>Rs. {item?.expectedDeposit}</span>
                      </p>
                      <div class="RoomAmenities">
                        <h4>Room Amenities</h4>
                        <ul>
                          {pgResult?.data?.[0]?.roomAmenities
                            ?.slice(0, 4)
                            ?.map((ele) => (
                              <li>
                                <span>
                                  {
                                    roomAmenitiesArray?.filter(
                                      (roomAmenity) => roomAmenity?.name == ele
                                    )?.[0]?.img
                                  }
                                </span>
                              </li>
                            ))}
                          <li
                            style={{ cursor: "pointer" }}
                            onClick={() => scrollHandle("Amenities")}
                          >
                            <small>+ More</small>
                          </li>
                        </ul>
                      </div>
                    </figcaption>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="writereview">
          <div class="OrderDetails">
            <article class="flex-column">
              <aside class="w-100">
                <h5>Description</h5>
                <p>{pgResult?.data?.[0]?.description}</p>
              </aside>
            </article>
          </div>
        </div>
      </section>

      <section>
        <div class="orderDetails-new">
          <div class="OrderDetails" id="Amenities">
            <h5>Amenities</h5>
            <article>
              <aside class="w-100">
                <div class="Amenities  Amenities2">
                  <ul>
                    {/* {pgResult?.data?.[0]?.availableAmenities?.map((amenity) => (
                      <li>{amenity}</li>
                    ))} */}
                    {pgResult?.data?.[0]?.availableAmenities?.map((ele) => (
                      <li>
                        <span>
                          {
                            amenitiesArray?.filter(
                              (roomAmenity) => roomAmenity?.name == ele
                            )?.[0]?.img
                          }
                          {ele}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </aside>
            </article>
          </div>

          <div class="OrderDetails" id="Rules">
            <h5>House Rules</h5>
            <article>
              <aside class="w-100">
                <div class="Amenities Amenities2">
                  <ul>
                    {pgResult?.data?.[0]?.pgHostelRule?.map((item) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                </div>
              </aside>
            </article>
          </div>
        </div>
      </section>

      <section>
        <div class="OrderDetails">
          <ul class="OrderDetails-3">
            {nearPlace?.map((place) => (
              <li>
                <a
                  onClick={() => setMapData({ ...mapData, place: place.value })}
                  className={place.value == mapData.place ? "active" : ""}
                >
                  {place.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </section>
      <div id="Location">
        <MapView
          mapData={mapData}
          currentLocation={{
            lat: pgResult?.data?.[0]?.location?.coordinates?.[0],
            lng: pgResult?.data?.[0]?.location?.coordinates?.[1],
          }}
        />
      </div>

      {pgResult?.data?.[0]?.ratings?.length > 0 && (
        <section class="Rating-body">
          <div class="OrderDetails" id="Review">
            <h5>Rating and Reviews</h5>
            <article>
              <aside class="w-100">
                <div class="d-flex">
                  <div class="RatingsTop">
                    <h1>{pgResult?.data?.[0]?.rating?.toFixed(1)}</h1>

                    <StarRatings
                      rating={pgResult?.data?.[0]?.rating}
                      starRatedColor="#f89117"
                      starDimension="20px"
                      starSpacing="0px"
                    />
                    <p>({pgResult?.data?.[0]?.ratings?.length} Reviews)</p>
                  </div>
                  <div class="ProgressBarArea">
                    <ul>
                      <li>
                        <label>5</label>

                        <Line
                          percent={
                            parseFloat(
                              ratingCounts?.["5"] /
                                pgResult?.data?.[0]?.ratings?.length
                            ) * 100
                          }
                          strokeWidth={4}
                          strokeColor="#f89117"
                        />

                        <strong>{ratingCounts?.["5"]}</strong>
                      </li>
                      <li>
                        <label>4</label>

                        <Line
                          percent={
                            parseFloat(
                              ratingCounts?.["4"] /
                                pgResult?.data?.[0]?.ratings?.length
                            ) * 100
                          }
                          strokeWidth={4}
                          strokeColor="#f89117"
                        />

                        <strong>{ratingCounts?.["4"]}</strong>
                      </li>
                      <li>
                        <label>3</label>

                        <Line
                          percent={
                            parseFloat(
                              ratingCounts?.["3"] /
                                pgResult?.data?.[0]?.ratings?.length
                            ) * 100
                          }
                          strokeWidth={4}
                          strokeColor="#f89117"
                        />

                        <strong>{ratingCounts?.["3"]}</strong>
                      </li>
                      <li>
                        <label>2</label>

                        <Line
                          percent={
                            parseFloat(
                              ratingCounts?.["2"] /
                                pgResult?.data?.[0]?.ratings?.length
                            ) * 100
                          }
                          strokeWidth={4}
                          strokeColor="#f89117"
                        />

                        <strong>{ratingCounts?.["2"]}</strong>
                      </li>

                      <li>
                        <label>1</label>

                        <Line
                          percent={
                            parseFloat(
                              ratingCounts?.["1"] /
                                pgResult?.data?.[0]?.ratings?.length
                            ) * 100
                          }
                          strokeWidth={4}
                          strokeColor="#f89117"
                        />

                        <strong>{ratingCounts?.["1"]}</strong>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="rating-tab">
                  <div class="CommonTabs">
                    <ul class="nav nav-tabs">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          data-toggle="tab"
                          onClick={() => setReviewType("Top-Rated")}
                          href="#top"
                        >
                          Top-Rated
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          href="#low"
                          onClick={() => setReviewType("Low-Rated")}
                        >
                          Low Rating
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          href="#favorites"
                          onClick={() => setReviewType("Newest")}
                        >
                          Newest
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          href="#gems"
                          onClick={() => setReviewType("Hidden-Gem")}
                        >
                          Hidden Gems
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                {reviewData?.data?.map((item) => (
                  <div class="RatingsBox">
                    <aside>
                      <div class="UserDetailsBox">
                        <h5>{item?.user?.[0]?.name}</h5>
                        <p> {dateTimeFormat(item?.updatedAt)} </p>
                        <span>
                          <img
                            src={
                              item?.user?.[0]?.profileImage
                                ? item?.user?.[0]?.profileImage
                                : require("../assets/images/profile-1.png")
                            }
                            alt="img"
                          />
                        </span>
                      </div>
                      <StarRatings
                        rating={item?.rating}
                        starRatedColor="#f89117"
                        starDimension="20px"
                        starSpacing="0px"
                      />
                    </aside>
                    <aside>
                      <p>{item?.feedbacks}</p>
                    </aside>
                  </div>
                ))}
              </aside>
            </article>
          </div>
        </section>
      )}

      <Footer />

      <Modal
        show={DeleteModal}
        onHide={handleDeleteModalClose}
        className="ModalBox"
      >
        <Modal.Body>
          <div class="Category">
            <a
              onClick={handleDeleteModalClose}
              class="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <h3>Give Feedback</h3>
            <h4>Enter details of the PG you want to rate</h4>
            <form>
              <div class="form-group">
                <label>Enter Locality</label>
                <input
                  type="text"
                  class="form-control"
                  disabled={true}
                  value={locality}
                  placeholder="Enter Locality."
                />
              </div>
              <div class="form-group">
                <label>Enter PG Name</label>
                <input
                  type="text"
                  disabled={true}
                  class="form-control"
                  value={pgName}
                  placeholder="Enter Pg name."
                />
              </div>
              <div class="form-group">
                <label>What's your current status?</label>
                <select
                  class="form-control"
                  value={currentStatus}
                  name="currentStatus"
                  onChange={handleChange}
                >
                  <option>--Select--</option>
                  {DropDownInterface?.map((item) => (
                    <option value={item?.value}>{item?.label}</option>
                  ))}
                </select>{" "}
                {errorMsg.currentStatus && !currentStatus && (
                  <p className="error-msg" style={{ color: "red" }}>
                    {errorMsg.currentStatus}
                  </p>
                )}
              </div>
            </form>
            <h4>Submit Rating</h4>

            <StarRatings
              rating={rating}
              changeRating={(e) => updateState({ ...iState, rating: e })}
              starRatedColor="#f89117"
              starDimension="20px"
              starSpacing="0px"
            />

            {errorMsg.rating && !rating && (
              <p className="error-msg" style={{ color: "red" }}>
                {errorMsg.rating}
              </p>
            )}

            <div class="form-group">
              <label>Write Feedback</label>
              <input
                type="text"
                value={feedbacks}
                name="feedbacks"
                onChange={handleChange}
                class="form-control"
                placeholder="Enter Feedback."
              />
              {errorMsg.feedbacks && !feedbacks && (
                <p className="error-msg" style={{ color: "red" }}>
                  {errorMsg.feedbacks}
                </p>
              )}
            </div>

            <a onClick={handleSubmit} class="Button">
              Submit
            </a>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ContacModal}
        onHide={handleContactModalClose}
        className="ModalBox"
      >
        <Modal.Body>
          <div class="Category">
            <a
              onClick={handleContactModalClose}
              class="CloseModal"
              data-dismiss="modal"
              style={{ cursor: "pointer" }}
            >
              ×
            </a>
            <h3>Contact Details</h3>
            <figure>
              <img
                src={
                  pgResult?.data?.[0]?.owner?.[0]?.profileImage
                    ? pgResult?.data?.[0]?.owner?.[0]?.profileImage
                    : require("../assets/images/user.jfif")
                }
                alt=""
              />
            </figure>
            <figcaption>
              <p>Name :- {pgResult?.data?.[0]?.owner?.[0]?.name}</p>
              <p>
                Phone Number :- {pgResult?.data?.[0]?.contactNumber && `${pgResult?.data?.[0]?.contactNumber} ,`}
                {pgResult?.data?.[0]?.owner?.[0]?.phone}
              </p>
              <p>
                {pgResult?.data?.[0]?.contactEmail &&
                pgResult?.data?.[0]?.contactEmail !== "" ? (
                  <>Email: {pgResult?.data?.[0]?.contactEmail}</>
                ) : (
                  ""
                )}
              </p>
              {/* <h6>
                Note : It will take some time for the property to be verified by
                our team. You will be notified once your property is listed
              </h6> */}
            </figcaption>
          </div>
        </Modal.Body>
      </Modal>
      
      {isOpen && (
        <Lightbox
          mainSrc={
            ImageModal.default?bannerImage?.[0]?.mediaUrl:pgResult?.data?.[0]?.propertyMedia?.filter(
              (ele) => ele?.mediaType == "Image"
            )?.[photoIndex]?.mediaUrl
          }
          nextSrc={
            pgResult?.data?.[0]?.propertyMedia?.filter(
              (ele) => ele?.mediaType == "Image"
            )?.[
              (photoIndex + 1) %
                pgResult?.data?.[0]?.propertyMedia?.filter(
                  (ele) => ele?.mediaType == "Image"
                )?.length
            ]
          }
          prevSrc={
            pgResult?.data?.[0]?.propertyMedia?.filter(
              (ele) => ele?.mediaType == "Image"
            )?.[
              (photoIndex +
                pgResult?.data?.[0]?.propertyMedia?.filter(
                  (ele) => ele?.mediaType == "Image"
                )?.length -
                1) %
                pgResult?.data?.[0]?.propertyMedia?.filter(
                  (ele) => ele?.mediaType == "Image"
                )?.length
            ]
          }
          onCloseRequest={() => SetImageModal({ isOpen: false, photoIndex })}
          onMovePrevRequest={() =>
            SetImageModal({
              photoIndex:
                (photoIndex +
                  pgResult?.data?.[0]?.propertyMedia?.filter(
                    (ele) => ele?.mediaType == "Image"
                  )?.length -
                  1) %
                pgResult?.data?.[0]?.propertyMedia?.filter(
                  (ele) => ele?.mediaType == "Image"
                )?.length,
              isOpen,
            })
          }
          onMoveNextRequest={() =>
            SetImageModal({
              photoIndex:
                (photoIndex + 1) %
                pgResult?.data?.[0]?.propertyMedia?.filter(
                  (ele) => ele?.mediaType == "Image"
                )?.length,
              isOpen,
            })
          }
        />
      )}
    </>
    
  );
};

export default PgDetails;
