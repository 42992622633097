import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import {url} from "../../../config/config"

export const loginApi = createAsyncThunk(
  "loginApi/postloginApi",
  async (payload) => {
    console.log("payload===>", payload);
    const response = await axios.post(`${url}user/login`,payload
    );

    return response.data
      ;
  }
);


const initialState = {
  loginStatus: false,
  user:{},
  status: 'idle',
  error: null,
}
const loginSlice = createSlice({
  name: 'userDataData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
      builder
          .addCase(loginApi.pending, (state) => {
              state.status = 'loading';
          })
          .addCase(loginApi.fulfilled, (state, action) => {
              state.status = 'succeeded';
              state.loginStatus = true;

              state.StaticContentData = action.payload;
          })
          .addCase(loginApi.rejected, (state, action) => {
              state.status = 'failed';
              state.error = action.error.message;
          });
  },
});

export default loginSlice.reducer;


