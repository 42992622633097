import React, { useEffect, useState, useRef } from "react";

const OtpInput = ({ length = 4, onOTPSubmit = () => {}, setOtpValue }) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef([]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    if (/\s/g.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    // Combine OTP
    const combinedOtp = newOtp.join("");
console.log(combinedOtp,combinedOtp.length==4, "combb")
    // Update parent state with OTP value
    setOtpValue(combinedOtp);

    // Submit trigger
    // if (combinedOtp.length==4) {
    //   onOTPSubmit(combinedOtp);
    // }
    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  
  };

  useEffect(() => {
    const combinedOtp = otp.join("");
    setOtpValue(combinedOtp); // Update otpValue with the latest combined OTP

    // Call the submit function when combinedOtp length is 4
    if (combinedOtp.length === 4) {
      onOTPSubmit(combinedOtp);
    }
  }, [otp]);

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !otp[index] && index > 0 && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleClick = (index, e) => {
    inputRefs.current[index].setSelectionRange(0, 1);

    // Validation
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  return (
    <>
      {otp.map((value, index) => {
        return (
          <input
            key={index}
            type="text"
            ref={(input) => {
              inputRefs.current[index] = input;
            }}
            value={value}
            onChange={(e) => handleChange(index, e)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onClick={(e) => handleClick(index, e)}
            className="form-control"
            maxLength="1"
          />
        );
      })}
    </>
  );
};

export default OtpInput;
