import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { isLoggedIn } from '../../../utils';

import {url} from "../../../config/config"


export const getSubscriptionList = createAsyncThunk('Subscription/subscriptionList', async (payload) => {
    console.log('payload===>', payload)
    const response = await axios.get(`${url}user/getAllSubscription?userId=${payload}`,{
        headers: {
            "Authorization": isLoggedIn("pgUser")
        },
        // params: payload
    });
    return response.data;
});

const initialState = {
    subscriptionData: [],
    status: 'idle',
    error: null,
}
const subscriptionSlice = createSlice({
    name: 'subscriptionData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSubscriptionList.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getSubscriptionList.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.subscriptionData = action.payload;
            })
            .addCase(getSubscriptionList.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default subscriptionSlice.reducer;