// rootReducer.js
import { combineReducers } from '@reduxjs/toolkit';

import profileReducer from "./slices/authSlice.js/getProfileSlice"
import otpReducer from './slices/authSlice.js/otpApiSlice'
import loginReducer from "./slices/authSlice.js/loginApiSlice";
import pgDetails from "./slices/pgSlices/pgDetailsSlice";
import pgList from "./slices/pgSlices/pgListingApiSlice";
import pgResult from "./slices/pgSlices/pgResultDetails";

import aboutusReducer from "./slices/staticContentSlice.js/aboutusApiSlice"
import termsReducer from "./slices/staticContentSlice.js/termsApiSlice"
import privacyReducer from "./slices/staticContentSlice.js/privacyApiSlice";
import flatListReducer from "./slices/flatSlice.js/flatListingSlice";
import flatDetails from "./slices/flatSlice.js/flatDetailsSilce";
import flatResult from "./slices/flatSlice.js/flatResultDetails";

import myPropertiyReducer from "./slices/mySlice/myPropertySlice";
import myWislistReducer from "./slices/mySlice/myWishlistSlice";
import reviewReducer from "./slices/pgSlices/reviewListingSlice";

import faqReducer from "./slices/staticContentSlice.js/faqApiSlice"

import userContactedReducer from "./slices/mySlice/myActiveContact"

import ticketReducer from "./slices/supportSlice/getTicketSlice"

import subscriptionReducer from "./slices/subscriptionSlice/getSubscriptionList"

import myPropertyResponseListReducer from "./slices/mySlice/propertyResponseSlice"









const rootReducer = combineReducers({

  otpData :otpReducer,
  userData : loginReducer,
  pgData :pgDetails ,
  pgListing :pgList,
  pgResult: pgResult,

  aboutusReducer:aboutusReducer,
  termsReducer:termsReducer,
  privacyReducer:privacyReducer,
  profileReducer:profileReducer,
  
  flatListReducer:flatListReducer,
  flatData :flatDetails ,
  flatResult: flatResult,

  myWislistReducer:myWislistReducer,
  myPropertiyReducer:myPropertiyReducer,
  reviewReducer :reviewReducer,
  faqReducer:faqReducer,
  userContactedReducer:userContactedReducer,
  ticketReducer:ticketReducer,

  subscriptionReducer:subscriptionReducer,
  myPropertyResponseListReducer:myPropertyResponseListReducer


  

});

export default rootReducer;
