import React, { useState } from "react";
import Header from "../Header";
import Footer from "../Footer";

import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { fetchOtp } from "../../reduxToolKit/slices/authSlice.js/otpApiSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { loginApi } from "../../reduxToolKit/slices/authSlice.js/loginApiSlice";

const initialState = {
  name: "",
  phone: "",
  email:"",
  whatsapp_opt_in: false,
  errorMsg: {},
};

const CreateAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {state} = useLocation()
  const [iState, updateState] = useState(initialState);

  const { phone, name,email, whatsapp_opt_in, errorMsg } = iState;

  const [isEnabled, setIsEnabled] = useState(false);

  const handleTerms = (e) => {
    if (isEnabled) {
      setIsEnabled(false);
    } else {
      setIsEnabled(true);
    }
  };

  const handleWhatsapp = (e) => {
    if (whatsapp_opt_in) {
      updateState({ ...iState, whatsapp_opt_in: false });
    } else {
      updateState({ ...iState, whatsapp_opt_in: true });
    }
  };

  const handleValidation = () => {
    let flag = true;
    let errorMsg = {};

    if (isEnabled == false) {
      flag = false;
      toast.error("Please accept confirmation");
      return false;
    }
    if (!name) {
      flag = false;
      errorMsg.name = " Name field is required";
    }
    // if (!email) {
    //   flag = false;
    //   errorMsg.email = " Email field is required";
    // }

    // if (!phone) {
    //   flag = false;
    //   errorMsg.phone = " Phone field is required";
    // } else if (phone < 10) {
    //   flag = false;
    //   errorMsg.phoneValid = " Phone number is not valid";
    // }

    updateState({ ...iState, errorMsg });

    return flag;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let payload2 = {
      name:iState.name,
      phone:state.phone,
      email:iState.email
  }
    let formIsValid = handleValidation();

    if (formIsValid) {
      dispatch(loginApi(payload2)).then((res) => {
        console.log(res,"resss")
        window.localStorage.setItem(
          "pgUser",
          JSON.stringify(res.payload.data)
        );
       if(res?.payload?.success){
        navigate("/")
       }else{
        toast.warning(res?.payload?.message)
       }
      });
      // dispatch(fetchOtp(phone)).then((res) => {
      //   if (res?.data?.isExist) {
      //     toast.error("User already exist with this phone no. !");
      //   } else {
      //     toast.success("Otp sent successfully !");

      //     setTimeout(() => {
      //       navigate("/login-verify", { state: iState });
      //     }, 1000);
      //   }
      // });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
console.log(name , value)
    if (name == "phone") {
      if (!/^[0-9]{0,10}$/.test(e.target.value) || e.target.value == "e")
        return;
      {
        updateState({ ...iState, phone: value });
      }
    } else {
      updateState({ ...iState,
         [name]: value 
        });
    }
  };

  return (
    <>
      <ToastContainer />

      <Header />

      <section>
        <div class="login-page">
          <aside>
            <form>
              <h2>Fill Your Details</h2>
              <div class="form-group">
                <label>Name</label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  class="form-control"
                  onChange={handleChange}
                  value={name}
                  name="name"
                />
                {errorMsg.name && !name && (
                  <p className="error-msg" style={{ color: "red" }}>
                    {errorMsg.name}
                  </p>
                )}
              </div>
              <div class="form-group">
                <label>Enter Your Email</label>
                <input
                  type="email"
                  placeholder="Enter your email"
                  class="form-control"
                  onChange={handleChange}
                  value={email}
                  name="email"
                />

{errorMsg.email && !email && (
                  <p className="error-msg" style={{ color: "red" }}>
                    {errorMsg.email}
                  </p>
                )}
              </div>
              {/* <div class="form-group">
                <label>Enter Phone Number</label>
                <input
                  type="text"
                  placeholder="Enter Phone Number"
                  class="form-control Number"
                  onChange={handleChange}
                  value={phone}
                  name="phone"
                />
                <span className="CountryCode">+91</span>

                {errorMsg.phone && !phone && (
                  <p className="error-msg" style={{ color: "red" }}>
                    {errorMsg.phone}
                  </p>
                )}

                {errorMsg.phoneValid && phone && (
                  <p className="error-msg" style={{ color: "red" }}>
                    {errorMsg.phoneValid}
                  </p>
                )}
              </div> */}
              <div class="form-group">
                <label class="CheckBox">
                  I want to receive important updates on&nbsp;Whatsapp
                  <input type="checkbox" onChange={(e) => handleWhatsapp(e)} />
                  <span class="checkmark"></span>
                </label>
                <label class="CheckBox">
                  I agree to PG Dekho &nbsp;T&amp;C,&nbsp;Privacy Policy,
                  &amp;&nbsp;Cookie Policy
                  <input type="checkbox" onChange={(e) => handleTerms(e)} />
                  <span class="checkmark"></span>
                </label>
              </div>
              <a class="login-btn" onClick={handleSubmit}>
               Continue
              </a>
              {/* <p>
                Already registered? <a href="index.html">Login Now</a>
              </p> */}
            </form>
          </aside>
          <article>
            <h2>Features you can access in your PG DEKHO Account</h2>
            <ul>
              <li><strong>Advanced Search Filters: </strong> Use filters to find PGs based on location, budget, amenities, and more.</li>
              <li><strong>AdvancedFavorites: </strong> Save your preferred PG options to a favorites list for easy comparison and access.</li>
              <li><strong>Reviews and Ratings:</strong> Access detailed reviews and ratings from other tenants to make informed decisions.</li>
            </ul>

          </article>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default CreateAccount;
