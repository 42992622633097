import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { s3Keys } from "../config/config";
import { toast, ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPgDetails } from "../reduxToolKit/slices/pgSlices/pgDetailsSlice";
import S3FileUpload from "react-s3";
import { createFlatApi } from "../reduxToolKit/slices/flatSlice.js/addFlatSlice";
import { fetchFlatDetails } from "../reduxToolKit/slices/flatSlice.js/flatDetailsSilce";

import { FaVideo } from "react-icons/fa";
import { FaCamera } from "react-icons/fa";

const FlatProperty5 = () => {
  const [iState, updateState] = useState({ errorMsg: {} });

  const { errorMsg } = iState;
  const { flatDetails } = useSelector((state) => state.flatData);

  const dispatch = useDispatch();

  // const [propertyMedia, setPropertyMedia] = useState([])
  const [images, setImages] = useState([]);
  const [tempImages, setTempImages] = useState([]);
  const [videos, setVideos] = useState([]);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();


  const handleValidation = () => {
    let flag = true;
    let errorMsg = {};

    if (images?.length == 0) {
      flag = false;
      errorMsg.images = " Images required";
    }

    // if (videos?.length == 0) {
    //   flag = false;
    //   errorMsg.videos = "Videos  required";
    // }

    updateState({ ...iState, errorMsg });

    return flag;
  };

  window.Buffer = window.Buffer || require("buffer").Buffer;

  const handleUpload = (e) => {
    const files = e.target.files;

    Array.from(files).forEach((file) => {
      if (file.type === "application/pdf") {
        toast.error("Only jpeg, jpg and png are allowed");
        return;
      }

      if (file) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          // Initiate the JavaScript Image object.
          var image = new Image();

          // Set the Base64 string return from FileReader as source.
          image.src = e.target.result;

          if (file.type === "video/mp4") {
            S3FileUpload.uploadFile(file, s3Keys)
              .then((data) => {
                let temp = [...videos];

                temp.push({
                  mediaType: "Video",
                  mediaTitle: "",
                  mediaUrl: data.location,
                  default: false,
                });

                setVideos(temp);
              })
              .catch((err) => console.log(err));
          }

          image.onload = function () {
            var height = this.height;
            var width = this.width;

            if (
              file.type === "image/jpeg" ||
              file.type === "image/jpg" ||
              file.type === "image/png"
            ) {
              S3FileUpload.uploadFile(file, s3Keys)
                .then((data) => {
                  setLoading(false);

                  // let temp = [];
                  setTempImages(images)
                  // temp = [...images];
                  // console.log(temp, "temp");
                  tempImages.push({
                    mediaType: "Image",
                    mediaTitle: "",
                    mediaUrl: data.location,
                    default: false,
                  });

                  setImages([...images, ...tempImages]);
                  // temp = [];
                  setTempImages([])
                })
                .catch((err) => console.log(err));
            } else {
              toast.error("Only jpeg, jpg and png are allowed");
            }
          };
        };
      }
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (images.every((elem) => elem.default === false)) {
      toast.warning("Please set default image on click star");
    } else{
    let isValid = handleValidation();
    if (isValid) {
      let data = {
        propertyMedia: images.concat(videos),
      };

      dispatch(createFlatApi(data))
        .then((res) => {
          if (res.payload.success) {
              navigate("/flat-property6");

            toast.success(res.message);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {});
    }
  }
  };

  const handleRemoveImage = (i) => {
    let tempArray = [...images];
    tempArray.splice(i, 1);
    setImages(tempArray);
  };

  const handleRemoveVideo = (i) => {
    let tempArray = [...videos];
    tempArray.splice(i, 1);
    setVideos(tempArray);
  };

  const handleDefaultForImage = (i) => {
    let tempArray = [...images];

    let newTemp = tempArray.map((item) => {
      return { ...item, ["default"]: false };
    });


    let obj = newTemp[i];
    obj["default"] = true;

    setImages(newTemp);
  };

  const handleDefaultForVideo = (i) => {
    let tempArray = [...videos];

    let newTemp = tempArray.map((item) => {
      return { ...item, ["default"]: false };
    });

    let obj = newTemp[i];
    obj["default"] = true;

    setVideos(newTemp);
  };

  useEffect(() => {
    let videoArray = flatDetails?.data?.[0]?.propertyMedia?.filter(
      (item) => item?.mediaType == "Video"
    );
    let imageArray = flatDetails?.data?.[0]?.propertyMedia?.filter(
      (item) => item?.mediaType == "Image"
    );

    setVideos(videoArray ? videoArray : []);
    setImages(imageArray ? imageArray : []);
  }, [flatDetails]);

  useEffect(() => {
    dispatch(fetchFlatDetails());
  }, []);

  return (
    <>
      <Header />

      <ToastContainer />


      <section>
        <div class="steps-details">
          <div class="StepBox">
            <ul>
              <li class="Previous">
                Step 1 <span>Room Details</span>
                <h6 class="Complete">
                  Complete{" "}
                  <span>
                    <i class="fa-solid fa-check"></i>
                  </span>
                </h6>
              </li>
              <li class="Previous">
                Step 2 <span>Location Details</span>
                <h6 class="Complete">
                  Complete{" "}
                  <span>
                    <i class="fa-solid fa-check"></i>
                  </span>
                </h6>{" "}
              </li>
              <li class="Previous">
                Step 3 <span>PG Details</span>{" "}
                <h6 class="Complete">
                  Complete{" "}
                  <span>
                    <i class="fa-solid fa-check"></i>
                  </span>{" "}
                </h6>
              </li>
              <li class="Previous">
                Step 4 <span>Amenities Details</span>{" "}
                <h6 class="Complete">
                  Complete{" "}
                  <span>
                    <i class="fa-solid fa-check"></i>
                  </span>{" "}
                </h6>
              </li>
              <li class="active">
                Step 5 <span>Gallery</span>{" "}
              </li>
              <li>
                Step 6 <span>Schedule</span>{" "}
              </li>
            </ul>
          </div>
          <div class="EditPropertyBox">
            <h3 class="Title">Gallery</h3>
            <div class="EditPropertyForm">
              <div class="row">
                <div class="col-md-6">
                  <div class="AddPhotosBox" onClick={() => document.getElementById('img').click()}>
                    <div class="Overlay">
                      <span>
                        {/* <img
                          src={require("../assets/images/camera-icon.png")}
                        /> */}
                         <FaCamera style={{fontSize:"40px",color:"#fd701e"}}/>
                      </span>
                      <p>Add Photos to get more responses</p>
                      <h5>90% Tenants contacts based on the photos uploaded</h5>
                      <div class="AddPhotosBtn">
                        <span>Add Photos</span>
                        <input
                          id="img"
                          type="file"
                          multiple
                          onClick={(e) => e.stopPropagation()}  
                          onChange={(e) => handleUpload(e)}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <small>Max size of image is 5mb and pixel is 1500*2000</small> */}
                  <small>Max size of image is 5mb </small>
                  <h3 class="Title">Photos</h3>
                  <div class="PhotoTypeArea">
                    <ul>
                      {images?.map((item, i) => (
                        <li>
                          <figure>
                            <img src={item?.mediaUrl} />
                            <figcaption>
                              <select
                                onChange={(e) => {
                                  let tempArray = [...images];
                                  let obj = tempArray?.[i];
                                  obj["mediaTitle"] = e.target.value;

                                  setImages(tempArray);
                                }}
                              >
                                <option>Photo Type</option>
                                <option value={"Kitchen"}>Kitchen</option>
                                <option value={"Hall"}>Hall</option>
                                <option value={"Bedroom"}>Bedroom</option>
                                <option value={"Room"}>Room</option>
                                <option value={"Outside"}>Outside</option>
                                <option value={"Washroom"}>Washroom</option>
                                <option value={"Balcony"}>Balcony</option>
                              </select>
                              <span
                                class="Icon"
                                onClick={() => handleDefaultForImage(i)}
                              >
                                <i
                                  class={
                                    item?.default
                                      ? "fa fa-star"
                                      : "fa-regular fa-star"
                                  }
                                ></i>
                              </span>{" "}
                            </figcaption>
                            <span
                              onClick={() => handleRemoveImage(i)}
                              class="Overlay"
                            >
                              <i class="fa-solid fa-xmark"></i>
                            </span>
                          </figure>
                        </li>
                      ))}
                    </ul>
                    {errorMsg.images && images?.length == 0 && (
                      <p className="error-msg" style={{ color: "red" }}>
                        {errorMsg.images}
                      </p>
                    )}
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="AddPhotosBox" onClick={() => document.getElementById('vdo').click()}>
                    <div class="Overlay">
                      <span>
                        {/* <img
                          src={require("../assets/images/camera-icon.png")}
                        /> */}
                         <FaVideo style={{fontSize:"40px",color:"#fd701e"}}/>
                      </span>
                      <p>Add Video to get more responses</p>
                      <h5>90% Tenants contacts based on the video uploaded</h5>
                      <div class="AddPhotosBtn">
                        <span>Add Video</span>
                        <input
                          type="file"
                          id="vdo"
                          onClick={(e) => e.stopPropagation()}  
                          onChange={(e) => handleUpload(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <small>Max size of video is 5mb</small>
                  <h3 class="Title">Video</h3>
                  <div class="PhotoTypeArea">
                    <ul>
                      {videos?.map((item, i) => (
                        <li>
                          <figure>
                            <video autoPlay style={{ width: "80px" }}
                            muted src={item?.mediaUrl} />
                            <figcaption>
                              <select
                                onChange={(e) => {
                                  let tempArray = [...videos];
                                  let obj = tempArray?.[i];
                                  obj["mediaTitle"] = e.target.value;

                                  setVideos(tempArray);
                                }}
                              >
                                <option>Video Type</option>
                                <option value={"Kitchen"}>Kitchen</option>
                                <option value={"Hall"}>Hall</option>
                                <option value={"Bedroom"}>Bedroom</option>
                                <option value={"Room"}>Room</option>
                                <option value={"Outside"}>Outside</option>
                                <option value={"Washroom"}>Washroom</option>
                                <option value={"Balcony"}>Balcony</option>
                              </select>
                              <span
                                class="Icon"
                                onClick={() => handleDefaultForVideo(i)}
                              >
                                <i
                                  class={
                                    item?.default
                                      ? "fa fa-star"
                                      : "fa-regular fa-star"
                                  }
                                ></i>
                              </span>{" "}
                            </figcaption>
                            <span
                              onClick={() => handleRemoveVideo(i)}
                              class="Overlay"
                            >
                              <i class="fa-solid fa-xmark"></i>
                            </span>
                          </figure>
                        </li>
                      ))}
                    </ul>

                    {errorMsg.videos && videos?.length == 0 && (
                      <p className="error-msg" style={{ color: "red" }}>
                        {errorMsg.videos}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="Buttons">
            <Link to="/flat-property4" class="PreviousBtn">
              Previous
            </Link>
            <a onClick={handleSubmit} class="SaveContinueBtn">
              Save &amp; Continue
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default FlatProperty5;
