import React, { useEffect, useRef, useState } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTicket } from '../../reduxToolKit/slices/supportSlice/getTicketSlice'
import { Modal } from 'react-bootstrap';
import { io } from "socket.io-client";
import { socket_url, url } from "../../config/config";
import axios from "axios";
import { fetchProfile } from '../../reduxToolKit/slices/authSlice.js/getProfileSlice'
import { useLocation } from 'react-router-dom'


const MyTicketSupport = () => {
  const scrollChat = useRef(null);
  const dispatch =  useDispatch();
  const location = useLocation();
  const userDetails = JSON.parse(window.localStorage.getItem("pgUser"));
  const {ticketData} = useSelector((state)=> state.ticketReducer)
  const userId = userDetails?._id;
  const socketUrl = socket_url;
  const userType = location?.state?.userType;
  const {profileData} = useSelector((state)=>state.profileReducer)
  const [modalChat , setModalChat] = useState(false)
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [ticketId , setTicketId]  = useState()
  const [ticketDetails , setTicketDetails] = useState()
  const [statusTkt , setStatusTkt] = useState("")
  



  const fetchChatHistory = async () => {
    let roomId = `${ticketId}-${userId}`;
    console.log(roomId, "roomID")
    let res = await axios.get(`${url}user/chat?roomId=${roomId}`, {});
    try {
      if (res?.data?.success) {
        setMessages(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const newSocket = io(socketUrl, {
      transports: ["websocket"], // Force WebSocket transport
      upgrade: false, // Prevent fallback to polling
    });

    setSocket(newSocket);

    // Join the chat room after connection
    newSocket.on("connect", () => {
      const payload = { userId, userType, ticketId, userId };
      newSocket.emit("joinRoom", payload);
    });

    // Handle errors
    newSocket.on("error", (error) => {
      console.error("Socket error:", error);
    });

    // Handle received messages
    newSocket.on("rev-msg",({ sender, senderType, receiver, message, timestamp }) => {
        setMessages((prevChat) => [
          ...prevChat,
          { sender, senderType, receiver, message, timestamp },
        ]);
      }
    );

    return () => {
      newSocket.off();
    };
  }, [ticketId]);

  useEffect(()=>{
    fetchChatHistory()
},[ticketId])

  const handleSendChat = (e) => {
    e.preventDefault();
    if (socket && newMessage.trim()) {
      const roomId = `${ticketId}-${userId}`;
      const payload = {
        roomId: roomId,
        sender: userId, // Assuming the user is the sender
        senderType: "user",
        message: newMessage,
      };
      socket.emit("send-msg", payload);
      setNewMessage(""); // Clear the input after sending
    }
  };

    useEffect(()=>{
      let payload={
        statusType:statusTkt
      }
        dispatch(fetchTicket(payload))
        dispatch(fetchProfile())
    },[statusTkt])

    const handleChatModalClose=()=>{
        setModalChat(false)
    }
    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        scrollChat.current?.scrollIntoView({ behavior: "smooth" });
      }, [messages]);

  return (
    <>

    <Header />


<section>
    <div class="my-proprties">
        <div class="right-search-area">
            <div class="activity-search">
             <div class="activity-search-tab">
                 <div class="activity-search-left" style={{width:"100%"}}>
                     <h3>My Tickets</h3>   
                     <div class="help-area" style={{justifyContent:"space-between" ,border:"none"}}>
          <aside>
            <div style={{cursor:"pointer"}}>
            <a  style={{marginRight:"8px"}} onClick={()=>setStatusTkt("resolved")}>Resolved</a>
            <a onClick={()=>setStatusTkt("unresolved")} >Unresolved</a>
            </div>
          </aside>
          </div>                     
                 </div>
             </div>

            {
                ticketData?.data?.map((elem,id)=>{
                    return(
                        <div class="search-body">
                        <a>
                           </a><div class="my-tkt"><a>
                               <p>Ticket ID: <strong>{elem?.ticket_id}</strong></p>
                               <p>You are a: <strong>{elem?.userType}</strong></p>
                               <p>Select issue you are facing from list: <strong>{elem?.issues}</strong></p>
                               </a><p><a>Describe your concern : <strong><div dangerouslySetInnerHTML={{__html: elem?.description}}></div> </strong></a><strong><p onClick={()=>{setTicketId(elem?._id);setModalChat(true);setTicketDetails(elem)}}>Open Chat Box</p></strong></p>
                            </div>
                            <span class={`${elem.status=="unresolved"?"red-tkt":elem.status=="resolved"?"green-tkt":"yellow-tkt"}`}>{elem?.status}</span>
                        
                    </div>
                    )
                })
            }

            {ticketData?.data?.length==0 && <p>Data not found</p>}

            </div>                        
         </div>
    </div>
   </section>

   <Footer />
   <Modal
        show={modalChat}
        onHide={handleChatModalClose}
        className=" "
        size="lg"
        centered
      >
        <Modal.Body className=''>
        <div class="OrderDetails">
            <h5>Chat - {ticketDetails?.ticket_id}</h5>
            <div class="ChatBoxArea">
              <div className="chatBox">
                {messages?.map((elem, index) => {
                  return elem.senderType !== "admin" ? (
                    <div className="ChatBoxLine" key={index}>
                      <figure>
                        <img
                          src={profileData?.data?.profileImage}
                          alt="User Profile"
                          style={{height:"100%"}}
                        />
                      </figure>
                      <figcaption>
                        <p>{elem.message}</p>
                        <h6>
                          {new Date(elem?.timestamp).getHours()}:
                          {new Date(elem?.timestamp)
                            .getMinutes()
                            .toString()
                            .padStart(2, "0")}{" "}
                          {new Date(elem?.timestamp).getHours() > 12
                            ? "pm"
                            : "am"}{" "}
                          (You)
                        </h6>{" "}
                        {/* Replace with actual time if available */}
                      </figcaption>
                    </div>
                  ) : (
                    <div className="ReplyBoxLine" key={index}>
                      <figcaption>
                        <p>{elem.message}</p>
                        <h6>
                          {new Date(elem?.timestamp).getHours()}:
                          {new Date(elem?.timestamp).getMinutes()}{" "}
                          {new Date(elem?.timestamp).getHours() > 12
                            ? "pm"
                            : "am"}
                        </h6>{" "}
                        {/* Replace with actual time if available */}
                      </figcaption>
                      <figure>
                        <img
                          src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                          alt="User Profile"
                        />
                      </figure>
                    </div>
                  );
                })}
                <div ref={scrollChat} />
              </div>
              <form
                onSubmit={(e) => handleSendChat(e)}
                className="chat-input-box "
              >
                <input
                  type="text"
                  placeholder="Write message"
                  className=""
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                />
                <button type="submit">Send</button>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default MyTicketSupport
