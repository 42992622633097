
import {  createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { isLoggedIn } from '../../../utils';

import {url} from "../../../config/config"



export const changePropertyStatusSlice = createAsyncThunk('myProperty/changeStatus', async (payload) => {

    try{

    const response = await axios.put(`${url}user/updateUserPropertyStatus`,payload, {
        headers: {
            "Authorization": isLoggedIn("pgUser")
        },
    });
    return response.data;

}
catch(err){
    return err;
}
});