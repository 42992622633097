import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AutoPlace from "./CitySearch";
import { fetchFlatlisting } from "../reduxToolKit/slices/flatSlice.js/flatListingSlice";
import Pagination from "react-js-pagination";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import StarRatings from "react-star-ratings";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { PiSwimmingPoolBold } from "react-icons/pi";
import { GiEscalator } from "react-icons/gi";
import { FaHouseUser } from "react-icons/fa";
import { BsGenderAmbiguous, BsTelephoneFill } from "react-icons/bs";
import { MdBedroomChild, MdOutlineCurrencyRupee } from "react-icons/md";
import { FaCartShopping } from "react-icons/fa6";
import { PiPipeLight } from "react-icons/pi";
import { FaParking } from "react-icons/fa";
import { GiFruitTree } from "react-icons/gi";

import MapView from "./MapView";
import { addFavApi } from "../reduxToolKit/slices/mySlice/addFovSlice";
import { IoLocationOutline } from "react-icons/io5";
const initialState = {
  verified: true,
  name: "",
  lat: "",
  long: "",
  gender: "",
  roomType: "",
  food: "",
  postBy: "",
  parking: "",
  sort: "",

  city: "",
  locality: "",
  street: "",
  address: "",
  availability: "",
  roomType: "",
  preferredTenant: "",
  availableAmenities: [],
  vehicleType: "",
  furnishing: "",
  location: {
    type: "point",
    coordinates: [],
  },
  apartmentType: "",
  facing: "",
  floor: "",
};

//"availableFor": "Co-Living", //"Boys", "Girls", "Co-Living"

const roomAmenitiesArray = [
  {
    name: "Lift",
    img: <GiEscalator style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "Intercom",
    img: <BsTelephoneFill style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "Swimming Pool",
    img: <PiSwimmingPoolBold style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "Club House",
    img: <FaHouseUser style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "Servent Room",
    img: <MdBedroomChild style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "Gas Pipeline",
    img: <PiPipeLight style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "Park",
    img: <GiFruitTree style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "Shopping Center",
    img: <FaCartShopping style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "Free Parking",
    img: <FaParking style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
];

const nearPlace = [
  {
    label: "Education",
    value: "university",
  },
  {
    label: "Restaurants",
    value: "restaurant",
  },
  {
    label: "Metro Station",
    value: "subway_station",
  },
  {
    label: "Shopping Center",
    value: "shopping_mall",
  },
  {
    label: "Hospital",
    value: "hospital",
  },
];

const furnishingDropdown = [
  {
    name: "Unfurnished",
    isSelect: false,
    value: "Unfurnished",
  },
  {
    name: "Semi Furnished",
    isSelect: false,
    value: "Semi-Furnished",
  },
  {
    name: "Fully Furnished",
    isSelect: false,
    value: "Fully-Furnished",
  },
];

const apartmentTypeDropdown = [
  {
    name: "Independent Villa",
    isSelect: false,
    value: "Independent Villa",
  },
  {
    name: "House Gated Society",
    isSelect: false,
    value: "House Gated Society",
  },
];

const preferredTenantDropdown = [
  {
    name: "Anyone",
    isSelect: false,
    value: "Anyone",
  },
  {
    name: "Family",
    isSelect: false,
    value: "Family",
  },
  {
    name: "Bachelor Female",
    isSelect: false,
    value: "Female",
  },
  {
    name: "Bachelor Male",
    isSelect: false,
    value: "Male",
  },
  {
    name: "Company",
    isSelect: false,
    value: "Company",
  },
];

const availabilityDropdown = [
  {
    name: "Immediate",
    isSelect: false,
    value: "Immediate",
  },
  {
    name: "Within a week",
    isSelect: false,
    value: "Week",
  },
  {
    name: "Within a month",
    isSelect: false,
    value: "Month",
  },
];

const floorDropdown = [
  {
    name: "Ground",
    isSelect: false,
    value: [0, 0],
  },
  {
    name: "1 - 5 Floors",
    isSelect: false,
    value: [1, 5],
  },
  {
    name: "6 - 10 Floors",
    isSelect: false,
    value: [6, 10],
  },
  {
    name: "11 - 15 Floors",
    isSelect: false,
    value: [11, 15],
  },
  {
    name: "16 - 20 Floors",
    isSelect: false,
    value: [16, 20],
  },
  {
    name: "21 - 25 Floors",
    isSelect: false,
    value: [21, 25],
  },
  {
    name: "26 - 30 Floors",
    isSelect: false,
    value: [26, 30],
  },
  {
    name: "31 - 35 Floors",
    isSelect: false,
    value: [31, 35],
  },
  {
    name: "36 - 40 Floors",
    isSelect: false,
    value: [36, 40],
  },
  {
    name: "41 - 45 Floors",
    isSelect: false,
    value: [41, 45],
  },
  {
    name: "46 - 50 Floors",
    isSelect: false,
    value: [46, 50],
  },
];

const facingDropdown = [
  {
    name: "North",
    isSelect: false,
    value: "North",
  },
  {
    name: "South",
    isSelect: false,
    value: "South",
  },
  {
    name: "East",
    isSelect: false,
    value: "East",
  },
  {
    name: "West",
    isSelect: false,
    value: "West",
  },
  {
    name: "North-East",
    isSelect: false,
    value: "North-East",
  },
  {
    name: "South-East",
    isSelect: false,
    value: "South-East",
  },
  {
    name: "North-West",
    isSelect: false,
    value: "North-West",
  },
  {
    name: "South-West",
    isSelect: false,
    value: "South-West",
  },
];
const FlatSearchResult = () => {
  const { flatData } = useSelector((state) => state.flatListReducer);
  const [propertyName, setPropertyName] = useState("");
  const { profileData } = useSelector((state) => state.profileReducer);
  const [searchAddress, setSearchAddress] = useState([]);

  const { state } = useLocation();

  const [readMore, setReadMore] = useState(false);

  const [cityArray, setCityArray] = useState([]);

  const [localPGdata, setLocalPgData] = useState([]);
  const [selectCity, setSeclectCity] = useState(state?.selectCity);
  const [pageNo, setPageNo] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const dispatch = useDispatch();
  const [budgetRange, setBudgetRange] = useState([1000, 20000]);

  const navigate = useNavigate();
  const [iState, updateState] = useState(initialState);

  const {
    address,
    city,
    gender,
    food,
    roomType,
    postBy,
    availability,
    parking,
    sort,
    verified,
    furnishing,
    preferredTenant,
    availableAmenities,
    vehicleType,
    apartmentType,
    facing,
    floor,
  } = iState;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [profileData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    updateState({ ...iState, [name]: value });

    if (name == "sort") {
      updateState({ ...iState, [name]: value });

      // dispatch(
      //   fetchFlatlisting({
      //     userId: profileData?.data?._id,
      //     food,
      //     gender,
      //     budget: {
      //       min: budgetRange?.[0],
      //       max: budgetRange?.[1],
      //     },
      //     sort: value,
      //     roomType,
      //     availability,
      //     parking,
      //     postBy,
      //   })
      // );
    }
  };

  const handleSearchbyName = (e) => {
    setPropertyName(e.target.value);
  };

  const handleCheckbox = (e, getter, prop) => {

    const { checked, name } = e.target;

    if (checked) {
      updateState({ ...iState, [name]: [...getter, prop] });
    } else {
      let tempArray = getter;
      let index = tempArray.findIndex((item) => item == prop);
      tempArray.splice(index, 1);

      updateState({ ...iState, [name]: tempArray });
    }
  };

  const filterBothTenUser = (e) => {
    const { checked } = e.target;

    if (checked) {
      updateState({ ...iState, postBy: ["Agent", "Owner"] });
    } else {
      updateState({ ...iState, postBy: [] });
    }
  };

  const handleRadio = (e, setter, prop) => {
    if (e.target.checked) {
      updateState({ ...iState, [setter]: prop });
    } else {
      updateState({ ...iState, [setter]: "" });
    }
  };

  const handleVerify = (e) => {
    const { checked, value } = e.target;

    if (checked) {
      updateState({ ...iState, verified: e.target.checked });
    } else {
      updateState({ ...iState, verified: e.target.checked });
    }
  };

  useEffect(() => {
    let tempArray = flatData?.data?.[0]?.data?.map((item) => {
      return { ...item, isFav: item?.isFavorite };
    });

    setLocalPgData(tempArray);
  }, [flatData]);

  const handleFav = (index) => {
    let tempArray = [...localPGdata];

    let obj = tempArray[index];

    obj.isFav = obj.isFav ? false : true;

    tempArray[index] = obj;

    setLocalPgData(tempArray);
    dispatch(addFavApi({ type: "FLAT", propId: obj._id }));
  };

  const handleRemove = (i) => {
    let tempArray = [...cityArray];
    let addressArray = [...searchAddress];
    tempArray.splice(i, 1);
    addressArray.splice(i, 1);

    setSearchAddress(addressArray);
    setCityArray(tempArray);
  };

  const handleFilterFlatData = () => {
    dispatch(
      fetchFlatlisting({
        location: searchAddress.length>0?searchAddress:state?.addressArray?.map((elem)=>elem),
        // locality: searchAddress?.map((elem)=>elem),
        userId: profileData?.data?._id,
        preferredTenant: iState?.preferredTenant
          ? iState.preferredTenant
          : state?.preferredTenant,
        city: selectCity,
        budget: {
          min: budgetRange[0],
          max: budgetRange[1],
        },
        availability: iState?.availability,
        sort: iState?.sort,
        postBy: iState?.postBy,
        parking: iState?.vehicleType,
        verified: iState?.verified,
        roomType: iState?.roomType ? iState?.roomType : state?.roomType,
        search: propertyName,
        apartmentType: iState.apartmentType,
        furnishing: iState.furnishing,
        floor: iState.floor,
        facing: iState.facing,
      })
    );
  };

  useEffect(() => {
    handleFilterFlatData();
  }, [iState, budgetRange, searchAddress,pageNo, propertyName]);

  useEffect(() => {
    updateState({
      ...iState,
      preferredTenant: state?.preferredTenant,
      budget: state?.budgetRange,
    });
    setCityArray(cityArray ? cityArray : state?.location);
    setSearchAddress(state?.addressArray?.map((elem)=>elem));
    setBudgetRange([
      state?.budgetRange?.min ? state?.budgetRange?.min : 1000,
      state?.budgetRange?.max ? state?.budgetRange?.max : 20000,
    ]);
  }, [state]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    setPageNo(pageNumber);
    window.scrollTo(0, 0);
  };

  const handeleMap=(e,item,scroll)=>{
    // e.preventDefault();
    e.stopPropagation();
    let item1={
      ...item,
      scroll:scroll
    }
    navigate(`/flat-details?id=${item?._id}`, {state:item1})
  }

  return (
    <>
      <Header />

      <section class="search-header">
        <div class="Filter">
          <div class="form-group ">
            <div class="gender">
              {/* <span>
                <img src={require("../assets/images/location.png")} alt="" />
              </span> */}
              <span><IoLocationOutline fontSize={"22px"} color="#fd701e"/></span>
              <select
                onChange={(e) => {setSeclectCity(e.target.value);setSearchAddress([]);}}
                name="selectCity"
                value={selectCity}
                class="form-control"
              >
                <option value={""}>Select city</option>
                {state?.citylist?.map((elem, id) => {
                  return (
                    <option key={id} value={elem.name}>
                      {elem.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div class="form-group CustomOne" style={{ position: "relative" }}>
            <div class="loaction">
              {/* <span>
                <img src={require("../assets/images/location.png")} alt="" />
              </span> */}
              <span><IoLocationOutline fontSize={"22px"} color="#fd701e"/></span>
              <AutoPlace
                updateState={updateState}
                iState={iState}
                address={address}
                cityArray={cityArray}
                setCityArray={setCityArray}
                searchAddress={searchAddress}
                setSearchAddress={setSearchAddress}
                selectCity={selectCity}
              />{" "}
            </div>
            <div className="ExpertiseList">
            <ul>
      {searchAddress &&
        searchAddress.map((item, i) => (
          <li key={i}>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-${i}`}>
                  {item}
                </Tooltip>
              }
            >
              <div style={{cursor:"pointer"}}>
                {item?.split(",")[0].slice(0, 9)}...{" "}
                <span
                  onClick={() => handleRemove(i)}
                  style={{ cursor: 'pointer' }}
                >
                  &times;
                </span>{" "}
              </div>
            </OverlayTrigger>
          </li>
        ))}
    </ul>
            </div>
          </div>

          <div class="form-group" >
            <div class="gender">
              <span>
                {" "}
                {/* <img src={require("../assets/images/gender.png")} alt="" /> */}
                <span><BsGenderAmbiguous fontSize={"22px"} color="#fd701e"/></span>
              </span>
              <select
                onChange={handleChange}
                name="preferredTenant"
                value={iState.preferredTenant}
                class="form-control"
              >
                <option value={""}>Tenant Type</option>
                <option value={"Anyone"}>Anyone</option>
                <option value={"Family"}>Family</option>
                <option value={"Bachelor Male"}>Bachelor Male</option>
                <option value={"Bachelor Femal"}>Bachelor Female</option>
                <option value={"Company"}>Company</option>
              </select>
            </div>
          </div>

          <div class="form-group CustomTwo">
            <div class="PriceSlideBox">
              <aside>
                {/* <span>
                  <img src={require("../assets/images/rs.png")} alt="" />
                </span> */}
                 <span><MdOutlineCurrencyRupee fontSize={"22px"} color="#fd701e"/></span>
                <label>Rent Range</label>
              </aside>
              <Slider
                allowCross={false}
                range
                min={500}
                step={500}
                value={budgetRange}
                max={50000}
                onChange={(e) => {
                  setBudgetRange(e);
                }}
              />
            </div>
          </div>

          {/* <div class="form-group">
            <a onClick={handleSearch} class="Button">
              Update
              <img src={require("../assets/images/left-arrow.png")} alt="" />
            </a>
          </div> */}
        </div>
      </section>

      <section>
        <div class="searching">
          <div class="row">
            <div class="col-sm-3">
              <div class="FilterArea">
                <div class="Filterbar">
                  <div class="form-group">
                    <label>Search by property name</label>
                    <div class="filterbarArea">
                      <span>
                        <img
                          src={require("../assets/images/search.png")}
                          alt=""
                        />{" "}
                      </span>
                      <input
                        type="search"
                        onChange={handleSearchbyName}
                        value={propertyName}
                        class="form-control"
                        placeholder="eg. New Delhi"
                      />
                    </div>
                  </div>
                </div>
                <h2>Filter by</h2>

                <div class="FilterBox">
                  <article>
                    <h3>Posted By</h3>
                  </article>
                  <aside>
                    <ul>
                      {/* <li>
                        <label class="CheckBox">
                          {" "}
                          Admin
                          <input
                            type="checkbox"
                            checked={postBy == "Admin" ? true : false}
                            onChange={(e) => handleRadio(e, "postBy", "Admin")}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </li> */}
                     <li>
                        <label class="CheckBox">
                          {" "}
                          Owner
                          <input
                            type="checkbox"
                            name="postBy"
                            checked={postBy?.includes("Owner")}
                            onChange={(e) => handleCheckbox(e, postBy, "Owner")}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label class="CheckBox">
                          Agent
                          <input
                            type="checkbox"
                            name="postBy"
                            checked={postBy?.includes("Agent")}
                            onChange={(e) => handleCheckbox(e, postBy, "Agent")}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label class="CheckBox">
                          Both
                          <input
                            type="checkbox"
                            // checked={postBy == "both" ? true : false}
                            onChange={filterBothTenUser}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                  </aside>
                </div>
                {/* <div class="FilterBox">
                  <article>
                    <h3>Availability</h3>
                  </article>
                  <aside>
                    <ul>
                      {availabilityDropdown?.map((item) => (
                        <li>
                          <label class="CheckBox">
                            {" "}
                            {item?.name}
                            <input
                              type="checkbox"
                              checked={
                                availability == item?.value ? true : false
                              }
                              onChange={(e) =>
                                handleRadio(e, "availability", item?.value)
                              }
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      ))}
                    </ul>
                  </aside>
                </div> */}
                <div class="FilterBox">
                  <article>
                    <h3>Parking</h3>
                  </article>
                  <aside>
                    <ul>
                      <li>
                        <label class="CheckBox">
                          {" "}
                          Bike
                          <input
                            type="checkbox"
                            checked={vehicleType == "Bike" ? true : false}
                            onChange={(e) =>
                              handleRadio(e, "vehicleType", "Bike")
                            }
                          />
                          <span class="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label class="CheckBox">
                          {" "}
                          Car
                          <input
                            type="checkbox"
                            checked={vehicleType == "Car" ? true : false}
                            onChange={(e) =>
                              handleRadio(e, "vehicleType", "Car")
                            }
                          />
                          <span class="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label class="CheckBox">
                          Both
                          <input
                            type="checkbox"
                            checked={vehicleType == "Both" ? true : false}
                            onChange={(e) =>
                              handleRadio(e, "vehicleType", "Both")
                            }
                          />
                          <span class="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                  </aside>
                </div>

                <div class="FilterBox">
                  <article>
                    <h3>Apartment Type</h3>
                  </article>
                  <aside>
                    <ul>
                      {apartmentTypeDropdown?.map((item) => (
                        <li>
                          <label class="CheckBox">
                            {" "}
                            {item?.name}
                            <input
                              type="checkbox"
                              checked={
                                apartmentType == item?.value ? true : false
                              }
                              onChange={(e) =>
                                handleRadio(e, "apartmentType", item?.value)
                              }
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      ))}
                    </ul>
                  </aside>
                </div>
                <div class="FilterBox">
                  <article>
                    <h3>Preferred Tenant</h3>
                  </article>
                  <aside>
                    <ul>
                      {preferredTenantDropdown?.map((item) => (
                        <li>
                          <label class="CheckBox">
                            {" "}
                            {item?.name}
                            <input
                              type="checkbox"
                              name="preferredTenant"
                              // checked={preferredTenant?.includes(item?.value)}
                              checked={
                                preferredTenant == item?.value ? true : false
                              }
                              onChange={(e) =>
                                handleRadio(e, "preferredTenant", item?.value)
                              }
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      ))}
                    </ul>
                  </aside>
                </div>

                <div class="FilterBox">
                  <article>
                    <h3>Furnishing</h3>
                  </article>
                  <aside>
                    <ul>
                      {furnishingDropdown?.map((item) => (
                        <li>
                          <label class="CheckBox">
                            {" "}
                            {item?.name}
                            <input
                              type="checkbox"
                              name="furnishing"
                              // checked={furnishing?.includes(item?.value)}
                              checked={furnishing == item?.value ? true : false}
                              // onChange={(e) =>
                              //   handleCheckBox(e, "furnishing", item.value)
                              // }
                              onChange={(e) =>
                                handleRadio(e, "furnishing", item.value)
                              }
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      ))}
                    </ul>
                  </aside>
                </div>

                <div class="FilterBox">
                  <article>
                    <h3>Floor</h3>
                  </article>
                  <aside>
                    <ul>
                      {floorDropdown?.map((item) => (
                        <li>
                          <label class="CheckBox">
                            {" "}
                            {item?.name}
                            <input
                              type="checkbox"
                              checked={floor == item?.value ? true : false}
                              onChange={(e) =>
                                handleRadio(e, "floor", item?.value)
                              }
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      ))}
                    </ul>
                  </aside>
                </div>

                <div class="FilterBox">
                  <article>
                    <h3>Facing</h3>
                  </article>
                  <aside>
                    <ul>
                      {facingDropdown?.map((item) => (
                        <li>
                          <label class="CheckBox">
                            {" "}
                            {item?.name}
                            <input
                              type="checkbox"
                              checked={facing == item?.value ? true : false}
                              onChange={(e) =>
                                handleRadio(e, "facing", item?.value)
                              }
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      ))}
                    </ul>
                  </aside>
                </div>
              </div>
            </div>

            <div class="col-sm-9">
              <div class="right-search-area">
                <div class="right-search-box">
                  <aside>
                    <h3>
                      Flat : {flatData?.data?.[0]?.metadata?.[0]?.total} search
                      results found
                    </h3>

                    <div class="verify">
                      <div class="Actions">
                        Verified Properties &nbsp;
                        <label class="switch">
                          <input
                            type="checkbox"
                            checked={verified}
                            onChange={handleVerify}
                          />
                          <span class="slider"></span>
                        </label>
                      </div>

                      <div class="form-group Recommended">
                        <label>Sort by</label>
                        <select
                          onChange={handleChange}
                          value={sort}
                          name="sort"
                          class="form-control"
                        >
                          <option value="">Recommended</option>
                          <option value="Price low to high">
                            Price Low to High
                          </option>
                          <option value="Price high to low">
                            Price High to Low
                          </option>
                          <option value="Rate low to high">
                            Rating High to Low
                          </option>
                          <option value="Rate high to low">
                            Rating Low to High
                          </option>
                        </select>
                      </div>
                    </div>
                  </aside>
                </div>

                {localPGdata?.map((item, i) => (
                  <a onClick={(e)=>handeleMap(e,item,false)}>
                    <div class="search-body">
                      <div class="search-verified">
                        {item?.verified && (
                          <span class="green-verfied">
                            <img
                              src={require("../assets/images/verified.png")}
                              alt=""
                            />{" "}
                            Verified
                          </span>
                        )}
                        <span class="boy">{item?.availableFor}</span>
                        <div class="left-search-verified-flat">
                          <img
                           src={item?.propertyMedia?.filter((elem) => elem.default)?.[0]?.mediaUrl}
                            //   item?.propertyMedia?.length > 0
                            //     ? item?.propertyMedia?.[0]?.mediaUrl
                            //     : require("../assets/images/search1.png")
                            // }
                            alt=""
                          />
                        </div>

                        <div class="right-search-verified">
                          <div class="tab-search">
                          <ul class="PreferredButton">
                            <li>{item?.postBy} Uploaded</li>
                          </ul>

                            <ul class="PreferredList">
                              <li>
                                <a onClick={() => handleFav(i)}>
                                  {item?.isFav ? (
                                    <img
                                      src={require("../assets/images/heart-red.png")}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src={require("../assets/images/heart.png")}
                                      alt=""
                                    />
                                  )}
                                </a>
                              </li>
                              {/* <li>
                                <a>
                                  <img
                                    src={require("../assets/images/shere.png")}
                                    alt=""
                                  />
                                </a>
                              </li> */}
                            </ul>
                          </div>
                         
                          <figcaption>
                            <h4>{item?.apartmentName}</h4>
                            &nbsp;&nbsp;
                            <StarRatings
                              rating={item?.rating}
                              starRatedColor="#f89117"
                              starDimension="15px"
                              starSpacing="0px"
                            />
                            &nbsp; &nbsp;
                            <p>
                              {item?.rating} ({item?.ratings?.length} Reviews)
                            </p>
                          </figcaption>
                          <h5>
                            <img
                              src={require("../assets/images/location.png")}
                              alt=""
                            />
                            {item?.address} , (near-{item?.street})
                          </h5>
                          <h6>
                            ₹ {item?.expectedRent}{" "}
                            <img
                              src={require("../assets/images/impot.png")}
                              alt=""
                            />
                          </h6>

                          <ul class="PreferredButton">
                            <li>{item?.BHKType}</li>
                            <li>{item?.apartmentType}</li>
                            {item?.gym && <li>Gym</li>}
                            {item?.parking && <li>Parking</li>}
                          </ul>

                          <ul class="flatOption">
                            <li>
                              <p>Furnishing</p>
                              <h6>{item?.furnishing}</h6>
                            </li>
                            <li>
                              <p>Carpet Area</p>
                              <h6>{item?.builtUpArea} sqft</h6>
                            </li>
                            <li>
                              <p>Floor</p>
                              <h6>
                                {item?.floor} out of {item?.totalFloor}
                              </h6>
                            </li>
                            <li>
                              <p>Preferred Tenant</p>
                              <h6>{item?.preferredTenant?.toString()} </h6>
                            </li>
                          </ul>

                          <ul className="facility-area-flat">
                            {item?.availableAmenities?.map((ele) => (
                              <li>
                                <span>
                                  {
                                    roomAmenitiesArray?.filter(
                                      (roomAmenity) => roomAmenity?.name == ele
                                    )?.[0]?.img
                                  }
                                </span>
                              </li>
                            ))}
                            {/* <li><small>+ More</small></li> */}
                          </ul>

                          <label>
                            {readMore
                              ? item?.description
                              : item?.description?.slice(0, 185)}{" "}
                            &nbsp;{" "}
                            <a
                              style={{ color: "black" }}
                              onClick={() => {
                                readMore
                                  ? setReadMore(false)
                                  : setReadMore(true);
                              }}
                            >
                              {readMore ? "Read Less..." : "Read More..."}
                            </a>
                          </label>

                          <div class="view-contact">
                            <Link
                              class="Button"
                              to="/flat-details"
                              state={item}
                            >
                              View Contact
                            </Link>
                            <a
                             onClick={(e)=>handeleMap(e,item,true)}
                              class="viewbutton"
                              style={{cursor:"pointer"}}
                            >
                              View on map{" "}
                              <img
                                src={require("../assets/images/orng-arrow.png")}
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </a>
                ))}

                <div>
                  {flatData?.data?.[0]?.metadata?.[0]?.total > 0 ? (
                    <div className="Pagination">
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={
                          flatData?.data?.[0]?.metadata?.[0]?.total
                        }
                        pageRangeDisplayed={5}
                        onChange={(e) => handlePageChange(e)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {/* <figure>
                  <img src={require("../assets/images/rating.png")} alt="" />
                </figure> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="download download2">
          <figure>
            <img src={require("../assets/images/banner-pg.png")} alt="" />
          </figure>

          <span>
            <h3 style={{color:"#000"}}>
              Download the mobile application for bonus <br /> coupons and
              travel codes
            </h3>
            <img
              src={require("../assets/images/android.png")}
              alt=""
              style={{ width: "100px" }}
            />
            <img
              src={require("../assets/images/ios.png")}
              alt=""
              style={{ width: "100px" }}
            />
          </span>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default FlatSearchResult;
