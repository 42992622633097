import React, { useEffect } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { fetchPropertyResponselist } from '../../reduxToolKit/slices/mySlice/propertyResponseSlice'
import Loader from '../Loader'

const PropertyResponses = () => {
    const dispatch = useDispatch();

    const {state} = useLocation()
const {propertyResponselistData} = useSelector((state)=>state.myPropertyResponseListReducer)
const loader  = useSelector((state) => state.myPropertyResponseListReducer.status)

    useEffect(()=>{
        dispatch(fetchPropertyResponselist({propertyId: state?.item?._id}))
    },[])
  return (
<>
{loader!="succeeded"?<Loader/>:""}
<Header />

<section>
    <div class="my-proprties">
        <div class="right-search-area">
            <div class="activity-search">
             <div class="activity-search-tab">
                 <div class="activity-search-left">
                     <h3>My Properties</h3>                        
                 </div>
             </div>
             <div class="search-body">
                 <div class="search-verified">
                     <div class="left-search-verified">
                         <img src={state?.item?.propertyMedia?.[0]?.mediaUrl} alt="" />
                     </div>

                     <div class="right-properties-listing">
                         <aside>
                         <h3>{state?.item?.name || state?.item?.apartmentName}</h3>
                         <p>Property ID <span>{state?.item?.flat_id ||state?.item?.pg_id}</span></p>
                         </aside>
                         <h3> 2 BHK Flats for Rent</h3>
                         <article>
                             <div>
                                 <p><img src={require("../../assets/images/black-location.png")} alt="" />{state?.item?.address}</p>
                             </div>
                         </article>
                     </div>    
                     
                 </div>
             </div>

            {
                propertyResponselistData?.data?.map((elem,id)=>{
                    return(
                        <div class="search-body">
                        <div class="response">
                            <aside>
                                <h3>{elem?.user?.[0]?.name}</h3>
                                <h4>{elem?.user?.[0]?.phone}</h4>
                                {/* <h5>Sector 55,Gurgaon</h5> */}
                            </aside>
                            <p>Contacted on {elem?.createdAt?.split("T")[0]} - {new Date(elem?.createdAt).toLocaleTimeString()}</p>
                        </div>
                    </div>
                    )
                })
            }
            </div>                        
         </div>
    </div>
   </section>

   <Footer />

</>  )
}

export default PropertyResponses