import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import OtpInput from "./OtpInput";
import { loginApi } from "../../reduxToolKit/slices/authSlice.js/loginApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchOtp } from "../../reduxToolKit/slices/authSlice.js/otpApiSlice";
import { useLocation, useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { url } from "../../config/config";


const LoginVerify = () => {
  const { data, loading } = useSelector((state) => state.otpData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  console.log(state, "stateee")
  const [otpValue, setOtpValue] = useState()


  const onOTPSubmit = async (combineOtp = "") => {
    // debugger
    if (otpValue.length > 0) {
      let payload = {
        phone: state.phone,
        otp: otpValue
      }
      let payload2 = {
        phone: state.phone,
      }
      if (state.otp == otpValue) {
        if (state.isExist) {
          dispatch(loginApi(payload2)).then((res) => {
            window.localStorage.setItem("pgUser", JSON.stringify(res.payload.data));
            navigate("/")
          })
          // navigate(-2, { replace: true });
        } else {
          navigate("/create-account", { state: { phone: state.phone } });
        }
      }
      // const response = await axios.post(`${url}user/verify_otp`, payload);
      // console.log(response,"responseee")
      //   if(response.data.verified){
      //     if (response.data.isExist) {
      //         dispatch(loginApi(payload2)).then((res) => {
      //             window.localStorage.setItem(
      //               "pgUser",
      //               JSON.stringify(res.payload.data)
      //             );
      //             // if (state?.redirectFrom == "login") {
      //             //   navigate(-2, { replace: true });
      //             // } else {
      //             //   navigate("/congratulation");
      //             // }
      //           });
      //           navigate(-2, { replace: true });
      //       }else{
      //         navigate("/create-account",{state:{phone:`91${state.phone}`}});
      //       }
      //   }else{
      //     toast.warning("Failed to verify OTP")
      //   }


    } else {
      toast.error("Verify OTP field is required");
    }
  };

  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const handleResend = (e) => {
    e.preventDefault();
    const reqData = { phone: state.phone };
    dispatch(fetchOtp(reqData))
      .then((res) => {
        console.log(res);
        setMinutes(1);
        setSeconds(30);
        toast.success("OTP has been sent successfully");
        // } else {
        //     toast.error('Something went wrong! Please try after sometime.');
        // }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };

  return (
    <>
      <ToastContainer />

      <Header />

      <section>
        <div class="login-page">
          <aside>
            <form>
              <h2>Verify</h2>
              <p>{data?.data?.otp}</p>
              <h6>
                You’ll receive a 4-digit verification code on {state?.phone}
                ,&nbsp;if you are registered with us
              </h6>
              <div class="form-group">
                <div class="OTPBox">
                  <OtpInput length={4} onOTPSubmit={onOTPSubmit} setOtpValue={setOtpValue} />
                </div>
                <span>{state?.otp}</span>
              </div>
              {seconds !== 0 && minutes !== 0 && (
                <a class="login-btn" onClick={onOTPSubmit}>
                  Verify OTP
                </a>
              )}
              {seconds > 0 || minutes > 0 ? (
                <p>
                  Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </p>
              ) : (
                ""
              )}
              {/* <p>You will receive OTP within </p> */}
              {seconds == 0 && minutes == 0 && (
                <a class="login-btn" onClick={handleResend}>
                  Resend
                </a>
              )}
            </form>
          </aside>
          <article>
            <h2>Features you can access in your PG DEKHO Account</h2>
            <ul>
              <li><strong>Advanced Search Filters: </strong> Use filters to find PGs based on location, budget, amenities, and more.</li>
              <li><strong>AdvancedFavorites: </strong> Save your preferred PG options to a favorites list for easy comparison and access.</li>
              <li><strong>Reviews and Ratings:</strong> Access detailed reviews and ratings from other tenants to make informed decisions.</li>
            </ul>

          </article>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default LoginVerify;
