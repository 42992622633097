
import {  createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { isLoggedIn } from '../../../utils';
import {url} from "../../../config/config"


export const completeUpgradeFlat = createAsyncThunk('complete/createComplete', async (payload) => {
    console.log('payload===>', payload)
    const response = await axios.post(`${url}user/flat/upgradeflat`,payload, {
        headers: {
            "Authorization": isLoggedIn("pgUser")
        },
    });
    return response.data;
});