
import {  createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { isLoggedIn } from '../../../utils';

import {url} from "../../../config/config"



export const callRequestApi = createAsyncThunk('Support/callRequest', async (payload) => {
console.log(payload, "payyy")
    try{

    const response = await axios.post(`${url}user/callRequest`,payload, {
        headers: {
            "Authorization": isLoggedIn("pgUser")
        },
    });
    return response.data;

}
catch(err){

    return err;


}
});