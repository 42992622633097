import React, { useEffect, useState } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { s3Keys } from '../../config/config'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProfile } from '../../reduxToolKit/slices/authSlice.js/getProfileSlice'
import { ToastContainer, toast } from "react-toastify";
import S3FileUpload from 'react-s3/lib/ReactS3'
import { ColorRing } from "react-loader-spinner";
import { updateProfileApi } from '../../reduxToolKit/slices/authSlice.js/editProfileSlice'
import { useNavigate } from 'react-router-dom'

const initialState = {
    name: "",
    email: "",
    errorMsg: {},
  };

const EditProfile = () => {

    const [iState, updateState] = useState(initialState)
    const [bannerloading, setLoading] = useState(false);

    const {name, email, errorMsg} = iState


    const {profileData} = useSelector((state)=>state.profileReducer)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [profileImage, setProfileImage] = useState('')

    useEffect(()=>{

        dispatch(fetchProfile())

    },[])
    const handleChange = (e) => {
        const { name, value } = e.target;
 
          updateState({ ...iState, [name]: value });
        
      };



      const handleValidation = () => {
        let flag = true;
        let errorMsg = {};
    
        var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (!email) {
          flag = false;
          errorMsg.email = " Email field is required";
        }
        if (!regex.test(email)) {
          flag = false;
          errorMsg.emailValid = "Please enter valid email";
        }
    
        if (!profileImage) {
          flag = false;
          errorMsg.profileImage = " Profile is required";
        }

        if (!name) {
            flag = false;
            errorMsg.name = " Name is required";
          }
    
    
    
        updateState({ ...iState, errorMsg });
    
        return flag;
      };

      window.Buffer = window.Buffer || require("buffer").Buffer;


      const handleUpload = (e, name) => {
        const file = e.target.files[0];
    
        if (file.type == "application/pdf") {
          toast.error("Only jpeg, jpg and png are allowed ");
        }
    
        if (file) {
          setLoading(true);
    
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function (e) {
            //Initiate the JavaScript Image object.
            var image = new Image();
    
            //Set the Base64 string return from FileReader as source.
            image.src = e.target.result;
    
            //Validate the File Height and Width.
            if (
              file.type === "image/jpeg" ||
              file.type === "image/jpg" ||
              file.type === "image/png"
            ) {
              let res = S3FileUpload.uploadFile(file, s3Keys)
                .then((data) => {
                  setLoading(false);
                  let tempData = data.location;

                  setProfileImage(tempData)
                })
                .catch((err) => {
                  setLoading(false);
                  console.log(err)
                });
            } else {
              toast.error("Only jpeg, jpg and png are allowed ");
              setLoading(false);
            }
          };
        }
      };



      useEffect(()=>{

        updateState({...iState, name: profileData?.data?.name, email: profileData?.data?.email})

        setProfileImage(profileData?.data?.profileImage)


      },[profileData])



            
  const handleSubmit = (e) => {
    e.preventDefault();

    let isValid = handleValidation()
    if (isValid) {


      let data= { 

        name,
        email,
        profileImage
        
        
      }

      dispatch(updateProfileApi(data))
      .then((res) => {
        if (res.payload.success) {

          setTimeout(() => {
            navigate("/my-profile");
          }, 1000);

          toast.success(res.message);

        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {});
    }
  };
    


return (
<>
<Header />
<ToastContainer />

<section>
        <div class="my-profile-profile">
            <div class="profile-img">
                <img src={require("../../assets/images/bg-profile.png" )} alt="" />
            </div>
            <div class="profile-area">
                <aside>
                    <figure>
                        <img src={profileImage?profileImage :require("../../assets/images/avatar.png")} alt="" />
                    </figure>
                    <span class="Overlay">

                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  <input
                    type="file"
                    onClick={(e) => (e.target.value = null)}
                    onChange={(e) => handleUpload(e)}
                  />

                  {bannerloading && (
                    <ColorRing
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperClass="blocks-wrapper"
                      colors={[
                        "#b8c480",
                        "#B2A3B5",
                        "#F4442E",
                        "#51E5FF",
                        "#429EA6",
                      ]}
                    />
                  )}

                



                  </span>
                  <span>Update Profile</span>
                </aside>
                <article>
                   
                   <form>
                       <div class="form-group">
                           <label>Full name</label>
                           <input type="text" class="form-control" placeholder="Enter Ful Name." value={name} name='name' onChange={handleChange}  />
                           {errorMsg.name && !name && (
                          <p className="error-msg" style={{ color: "red" }}>
                            {errorMsg.name}
                          </p>
                        )}

                   
                           
                       </div>
                       <div class="form-group">
                           <label>Email</label>
                           <input type="text" class="form-control" placeholder="Enter Email ID ." value={email} name='email' onChange={handleChange} />
                           {errorMsg.email && !email && (
                          <p className="error-msg" style={{ color: "red" }}>
                            {errorMsg.email}
                          </p>
                        )}

                        {errorMsg.emailValid && email && (
                          <p className="error-msg" style={{ color: "red" }}>
                            {errorMsg.emailValid}
                          </p>
                        )}
                       </div>
                       <button onClick={handleSubmit} class="Button">Save Changes</button>
                   </form>
                   <h3> <a class="editbtn" >Reset Password</a></h3>
               </article>
            </div>
        </div>
    </section>
<Footer />

</>  )
}

export default EditProfile